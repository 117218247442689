$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";
#app {
	.scl-stepper {
		&__steps {
			display: flex;
			justify-content: center;
			.v-divider {
				max-width: 100px;
			}
		}

		.v-stepper {
			&__header {
				box-shadow: none;
			}
			&__content {
				padding-top: 0;
			}
			&__step {
				&__step {
					width: 25px;
					height: 25px;
					border: 3px solid #333;
					.v-icon {
						&:before {
							display: none;
						}
					}
				}
				&--active {
					.v-stepper__step__step {
						background-color: white !important;
						&:before {
							content: "";
							width: 8px;
							height: 8px;
							background-color: #333;
							border-radius: 50%;
							position: relative;
							left: 4px;
						}
					}
				}
				&--complete {
					.v-stepper__step__step {
						background-color: #333;
					}
				}
				&--inactive {
					.v-stepper__step__step {
						background-color: white;
					}
				}
			}
		}
	}
}
