$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";
// grid list

#app {
	.scl-grid-list {
		background-color: #fff;
		padding: 71px 0 160px;
		&--bg {
			background: url("/assets/img/concrete-bg.jpg") repeat;
		}
		&__title {
			color: $primary-color;
			font-family: $font-name-klavika-bold;
			font-size: 43px;
			letter-spacing: 1.5px;
			line-height: 53px;
			margin: 0 0 25px;
			text-transform: uppercase;
		}
		&__btn-container {
			padding-top: 40px;
			text-align: center;
		}
	}
}
