.title-2 {
	font-size: 18px;
}

.caption {
	font-size: 12px;
}

.text--orange {
	color: $color-orange;
}

.text--normal {
	font-weight: normal;
}

a {
	transition: color timing(faster);
}
p {
	font-size: $font-size-base;
}
@font-face {
	font-display: swap;
	font-family: 'Neue Hass Grotesk Display Pro';
	font-style: normal;
	font-weight: $font-weight-normal;
	src: url('#{$font-path}/NeueHaasDisplayLight.woff') format('woff'), url('#{$font-path}/NeueHaasDisplayLight.woff2') format('woff2');
}

@font-face {
	font-display: swap;
	font-family: 'Neue Hass Grotesk Display Pro';
	font-style: italic;
	font-weight: $font-weight-normal;
	src: url('#{$font-path}/NeueHaasDisplayLightItalic.woff') format('woff'), url('#{$font-path}/NeueHaasDisplayLightItalic.woff2') format('woff2');
}

@font-face {
	font-display: swap;
	font-family: 'Neue Hass Grotesk Display Pro';
	font-style: normal;
	font-weight: $font-weight-semi-bold;
	src: url('#{$font-path}/NeueHaasDisplayMediu.woff') format('woff'), url('#{$font-path}/NeueHaasDisplayMediu.woff2') format('woff2');
}

@font-face {
	font-display: swap;
	font-family: 'Neue Hass Grotesk Display Pro';
	font-style: normal;
	font-weight: $font-weight-bold;
	src: url('#{$font-path}/NeueHaasDisplayRoman.woff') format('woff'), url('#{$font-path}/NeueHaasDisplayRoman.woff2') format('woff2');
}
