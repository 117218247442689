#app {
	.wrapper-content__container {
		padding-left: 73px;

		@media (max-width: $sm) {
			padding-left: 0;
		}
	}
	// .scl-side-nav.mini-menu {
	// 	border-right: 10px solid $color-grey8;
	// }
	.scl-quote-request {
		&__container {
			padding: 24px 0;
			border-bottom: 1px solid $color-grey5;

			&:first-child {
				padding-top:0;
			}
			
			&:last-child {
				border-bottom: 0;
				align-items: center;
			}
		}
		&__title {
			margin-bottom: 5px;
			font-weight: 300;
			font-size: 24px;
			line-height: 1.25;
			color: $color-black;
		}
		&__other {
			display: flex;
			align-items: center;
			.v-input--checkbox {
				flex: 0 1 auto;
				padding: 0
			} .v-input__slot {
				margin:0;
			}
		}
	}

	.scl-form {
		&.create-quote {		
			padding: 0 24px 24px 24px;
			background-color: $color-grey8;
			
			@media (max-width: $sm) {
				padding: 0 16px 16px 16px;
			}

			.v-input .v-input__icon--append .v-icon {
				color: $color-orange;
				font-weight: 400;
			}

			.v-input--checkbox label,
			.v-input--radio-group label{
				color: $color-orange;
				margin-top: 8px;
			}

			.v-input--checkbox.v-input--is-readonly label,
			.v-input--radio-group .v-radio--is-disabled label {
				color: $accent;
				cursor: default;
			}

			.v-input--selection-controls {
				margin-top: 0;
				padding-top: 0;
			}

		}

		.container{
			background: $color-white;
			box-shadow: 0px 2px 2px rgba(61, 57, 53, 0.08);
		}

		&__input {
			.v-input__slot {
				background: $colour-input !important;
				border: 1px solid $colour-border;
				border-radius: 0;
				box-shadow: inset 0 1px 2px hsla(0, 0%, 4%, 0.1);
			}
			input {
				margin-top: 0px !important;
				color: $accent;
				font-weight: 500;
			}

			&--cal-icon {
				cursor: pointer;
			}
			.v-text-field__details {
				padding: 0;
			}
		}
		&__select {
			.v-select__selection {
				margin-top: 8px !important;
			}
			input {
				margin-bottom: 8px;
			}
		}
		&__label,
		label {
			display: block;
			margin-bottom: 6px;
			color: $accent;
			font-weight: 500;
			font-size: 16px;
			line-height: 1.3;
			text-transform: inherit !important;

			&.v-label--active {
				color: $color-orange;
			}
			&--additional {
				float: right;
				color: $color-grey1;
				font-weight: normal;
				font-size: 12px;
				font-family: $font-name-pt-sans;
				line-height: 22px;
			}
			&.text--orange {
				color: $color-orange;
			}
		}
		&__btn {
			height: 56px;
			font-weight: 500;
			font-size: 17px;
			font-family: $font-name-klavika-medium;
			line-height: 1.1;
			letter-spacing: 1.5px;
			text-transform: uppercase;
		}
		&__remove {
			width: 48px;
			height: 48px;
			margin-top: 26px;
			padding: 6px;
			color: #fff !important;
			background-color: $color-grey1 !important;
			pointer-events: none;
			
			@media (max-width: $md) {
				margin-top: 0;
			}

			&--active {
				background-color: $color-orange !important;
				cursor: pointer;
				pointer-events: initial;
			}
		}
		&__colon {
			padding: 14px 10px;
		}

		.layout .flex {
			padding: 0 12px;
		}
	}

	.equipment-row {
		@media (max-width: $md) {
			flex-flow: column;
		}

		&__number-input {
			flex-basis: 20%;
			max-width: 20%;
			margin-bottom: 24px;

			@media (max-width: $md) {
				flex-basis: 40%;
				max-width: 40%;
			}

			@media (max-width: $sm) {
				flex-basis: 60%;
				max-width: 60%;
			}
		}

		&__remove-product {
			flex-basis: 72px;
			max-width: 72px;
		}
	}
	.v-tabs__bar {
		background-color: transparent;
	}
	.v-tabs__wrapper {
		overflow: visible;
		contain: initial;
	}
	.v-tabs__slider-wrapper {
		display: none;
	}
	.v-tabs__item {
		border: 1px solid $color-grey5;
		border-bottom: none;
		color: $accent !important;
		background: $colour-input;
		font-size: $font-size-base;
		font-weight: $font-weight-semi-bold;
		opacity: 1;
		text-transform: capitalize;
		&--active {
			box-shadow: 0 1px 0 0 #fff, 0 0 0 #fff;
			color: $color-orange !important;
			background: $color-white;
		}

		&--disabled {
			color: $accent !important;
		}
	}

	.v-window.scl-quote-tabs__tab-content {
		background: $color-white;
		border: 1px solid $color-grey5;
		padding: gutter-size(6);
	}
	.scl-quote-delivery-pickup {
		&__pickup {
			.color--primary {
				color: $color-orange;
			}
		}
		&__input {
			.v-input__slot {
				background: $colour-input !important;
				border: 1px solid $colour-border;
				border-radius: 0;
				label {
					color: $color-grey1 !important;
				}
				.v-icon {
					color: $color-orange;
				}
			 }
			 &.v-input--is-disabled .v-input__slot {
				 border: 0;

				 input {
					 color: $accent;
				 }
			 }
			 &--suburb{
				margin-left: -1px;
				background: $color-white;
				color: $accent;
				border: 1px solid $color-grey5;
				box-shadow: 0px 2px 2px rgba(61, 57, 53, 0.08);
				
				a{
					color: $accent;
					
					&:hover, &:active, &:active {
						color:  $color-orange;
					}
				}
			 }
		}
		&__branch-results {
			.v-input--radio-group {
				margin-top: 0;
				padding-top: 0;
			}
			.v-input--selection-controls .v-input__control {
				flex-basis: 100%;
			}
			.v-list__tile {
				height: auto;
				padding: gutter-size(3) gutter-size(2);
				border-bottom: 1px solid $colour-border;
			}
			.v-list__tile__action {
				min-width: gutter-size(8);
				.v-icon {
					color: $accent;
				}
			}
			.v-list__tile__title {
				height: auto;
				color: $accent;
				font-size: $font-size-base;
				font-weight: $font-weight-semi-bold;
				line-height: $line-height-base;
			}
			.v-list__tile__sub-title {
				line-height: 1.2;
				color: $color-grey1;
				white-space: normal;
			}
			.v-list__tile__distance {
				color: $accent;
			}
		}
	}

	.v-input--is-readonly input {
		cursor: default;
	}

	.select-margin-top {
		margin-top: -10px;
	}

	.far {
		font-weight: 300; 
	}

	.v-form>.container>.layout>.flex {
		padding: 8px;
	}
}
