$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";
#app {
	.scl-exception-table {
		thead {
			th {
				.v-icon {
					left: 10px;
					position: relative;
					top: 5px;
				}
			}
		}
	}
}
