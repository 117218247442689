$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";
#app {
	.scl-user-status {
		.container.fluid {
			max-width: 100% !important;
		}
		&__title {
			font-size: 24px;
		}
		&__step-input {
			display: flex;
		}
		&__status-indicator {
			position: relative;
			top: 2px;
		}
	}
}
