$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";
#app {
	.scl-update-status {
		.container.fluid {
			max-width: 100% !important;
		}
		&__status-item {
			&:last-of-type {
				border-bottom: none;
			}
		}
	}
}
