$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";
#app {
	.scl-ww {
		// headings
		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			color: $primary-color;
			font-family: $font-name-klavika-bold;
			letter-spacing: 1px;
			margin-bottom: 0.5rem;
			text-rendering: optimizeLegibility;
			text-transform: uppercase;
		}
		h1 {
			font-size: 3.125rem;
		}
		h2 {
			font-size: 2.75rem;
		}
		h3 {
			font-size: 2.5rem;
		}
		h4 {
			font-size: 31px;
			margin-bottom: 2.5rem;
		}
		h5 {
			font-size: 1.5rem;
		}
		h6 {
			font-size: 15px;
		}

		// text
		p {
			color: $primary-color;
			font-family: $font-name-klavika-medium;
			font-size: 14px;
			line-height: 1.6;
			margin-bottom: 1rem;
		}
		ul,
		ol {
			line-height: 1.9;
			list-style-type: disc;
			margin-bottom: 1rem;
			padding: 0;
			li {
				font-size: 14px;
				margin-left: 24px;
				padding-left: 10px;
			}
		}
		.highlight {
			p {
				font-size: 23px;
				line-height: 36px;
			}
		}
		.img-right {
			display: inline-block;
			float: right;
			margin: 32px 0;
			max-width: 100%;
			img {
				height: auto;
				width: 100%;
			}
		}
		.img-left {
			display: inline-block;
			float: left;
			margin: 32px 0;
			max-width: 100%;
			img {
				height: auto;
				width: 100%;
			}
		}
		strong {
			font-weight: bold;
		}

		// interaction
		a {
			color: $color-orange;
			text-decoration: none;
			#{$interact} {
				color: $color-orange-hover-light;
			}
		}
		@media (min-width: 768px) {
			.img-right {
				margin: 8px 0 32px 72px;
				max-width: 50%;
			}
			.img-left {
				margin: 8px 72px 32px 0;
				max-width: 50%;
			}
		}
	}
}
