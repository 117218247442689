$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";
#app {
	.scl-history-modal {
		&__container {
			font-size: 16px;
			tbody tr td:first-child,
			thead tr th:first-child {
				padding-left: 0;
			}
			tbody tr td:last-child,
			thead tr th:last-child {
				color: $color-orange;
			}
		}
		&__container-layout {
			overflow-y: auto;
		}
		&__actions {
			position: absolute;
			bottom: 0;
			display: flex;
			justify-content: flex-end;
			width: 100%;
			@media (max-width: $md) {
				justify-content: center;
			}
		}
		&__data-table {
			overflow-y: auto;
		}
	}
}
