$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";
#app {
	.scl-top-nav {
		position: relative;

		// large nav
		&__toolbar {
			background-color: $color-orange;
			box-shadow: none;
			.v-toolbar__content {
				padding-left: 0;
				padding-right: 0;
			}
		}
		&__container {
			height: 100%;
			margin: 0 auto !important;
			max-width: none !important;
			padding-bottom: 0;
			padding-top: 0;
		}
		&__drawer {
			align-items: center;
			display: flex;
			height: 100%;
		}
		&__drawer-btn {
			&--hidden {
				visibility: hidden;
			}
		}
		&__menu-container {
			display: flex;
			flex-direction: row;
			height: 45px;
			> a {
				height: 100%;
				text-decoration: none;
				&:hover {
					color: $color-white;
				}
			}
		}
		&__menu-email {
			border-right: 1px solid #e8e7e5;
		}
		&__menu-logout {
			color: $color-white;
			.v-icon {
				margin-bottom: 3px;
			}
		}
	}
}
