$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";
#app {
	.scl-send-invitation-modal {
		&__container {
			h3 {
				font-family: $font-name-klavika-bold;
			}
		}
		&__container-layout {
			overflow-y: auto;
		}
		&__component {
			.underline {
				border-bottom: 1px solid #cccbc9;
			}

			.v-card__actions {
				@media (max-width: $sm) {
					display: flex;
					flex-direction: column-reverse;
					.v-btn {
						width: 100%;
					}
				}
			}
		}
		&__data-table {
			overflow-y: auto;
			font-size: 16px;
			thead {
				tr {
					height: 40px;
				}
				th {
					padding-left: 0;
				}
			}
			tbody tr td {
				padding-left: 5px;
			}
			tr td {
				font-size: 14px;
				padding: 10px;
			}
		}
	}
}
