#app {
	.scl-edit-access-modal {
		&__actions {
			border-top: 1px solid $color-grey7;
			bottom: 0;
			display: flex;
			justify-content: flex-end;
			position: absolute;
			width: 100%;
			@media (max-width: $md) {
				justify-content: center;
			}
		}
		&__checkbox {
			&--indeterminate {
				.v-icon {
					background-color: rgba($color-orange, 0.5);
				}
			}
			.v-input__slot {
				align-items: flex-start;
				display: flex;
			}
			.v-label {
				font-size: 15px;
				letter-spacing: 0px;
				text-transform: initial;
			}
			.fa-minus-square {
				&:before {
					border: 2px solid $color-9B9A99;
					border-radius: 3px;
					content: "";
					height: 22px;
					position: absolute;
					width: 22px;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
				}
				&:after {
					background-color: rgba(255, 70, 0, 0.7);
					content: "";
					height: 10px;
					left: 46%;
					position: absolute;
					top: 46%;
					transform: translate(-50%, -50%);
					width: 10px;
				}
			}
		}
		&__checkbox--subcategory {
			.v-label {
				font-family: "Arial";
				font-size: 16px;
				font-weight: 500;
			}
		}
	}
	.scl-edit-access-modal-component {
		height: 100%;
		min-height: 80%;
		overflow: hidden;
		.v-form {
			height: 80%;
			overflow-y: scroll;
			position: absolute;
			top: 50px;
			width: 100%;
			@media (min-width: $md) {
				top: 10%;
			}
		}
		.topline {
			border-top: 1px solid #ccc;
		}
		// fix the checkbox cannot be selected with custom indeterminate style
		.v-input--selection-controls__ripple {
			z-index: 1;
		}
	}
}
