$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";
#app {
	.scl-page-tabs {
		.v-tabs {
			&__icon {
				display: flex;
			}
			&__item {
				border: none;
				border-right: 1px solid $color-E5E5E5;
				color: $color-black;
				font-size: 18px;
				font-weight: 700;
				padding: 0 20px;
				text-transform: capitalize;
				transition-duration: 0s;
				&--active {
					background-color: $color-white;
					color: $color-orange;
				}
			}
			&__container {
				height: 52px;
			}
			&__div {
				background-color: rgba($color-white, 0.5);
			}
			&__bar {
				background-color: $color-E5E5E5;
			}
		}
		&--wedge {
			.v-tabs__item {
				background-color: $color-9B9A99;
				&--active {
					background-color: $color-white;
					color: $color-orange;
				}
			}
			.v-tabs__div {
				height: 100%;
				position: relative;
				a {
					padding: 0 30px;
				}
				&:first-child {
					.v-tabs__item:before {
						border-top: 52px solid $color-white;
					}
				}
				&:last-child {
					.v-tabs__item:after {
						border-top: 52px solid $color-white;
					}
				}
				.v-tabs__item:before {
					border-left: 20px solid transparent;
					border-top: 52px solid rgb(181, 180, 180);
					content: "";
					display: none;
					left: -1px;
					position: absolute;
					top: 0;
					transform: rotate(180deg);
				}
				.v-tabs__item:after {
					border-left: 20px solid transparent;
					border-top: 52px solid rgb(181, 180, 180);
					content: "";
					display: none;
					position: absolute;
					right: 0;
					top: 0;
					z-index: 1;
				}
				.v-tabs__item--active:before,
				.v-tabs__item--active:after {
					display: block;
				}
				&__container {
					background-color: $color-E5E5E5;
				}
			}
		}
	}
}
