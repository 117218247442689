$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";
#app {
	.scl-admin-table {
		.container.fluid {
			max-width: 100% !important;
		}
		thead {
			th {
				color: $color-3D3935;
				text-align: center !important;
				.v-icon {
					color: $color-3D3935;
					float: left;
					opacity: 1;
					position: relative;
					top: 4px;
					width: 100%;
				}
				&.desc {
					.v-icon {
						top: -2px;
					}
				}
				&:first-child {
					background-color: $color-white;
				}
			}
		}
		table > tbody > tr > td:nth-child(2) {
			// z-index: 9998;
			background: white;
			border-right: 2px solid $color-CCCBC9;
			left: -1px;
			margin-right: 8px;
			padding-right: 48px;
			position: sticky !important;
			position: -webkit-sticky !important;

			@media (min-width: $lg) {
				background: none;
				border: none;
				margin-right: 0;
				padding-right: 0;
			}
		}
		table > thead > tr > th:nth-child(2) {
			// z-index: 9998;
			background-color: white;
			left: -1px;
			margin-right: 8px;
			padding-right: 48px;
			position: sticky !important;
			position: -webkit-sticky !important;

			@media (min-width: $lg) {
				background: none;
				margin-right: 0;
				padding-right: 0;
			}
		}

		tbody > tr {
			&.grey-line {
				td,
				div {
					color: $color-grey2;
				}
			}
		}

		a {
			.grey-link {
				color: $color-3D3935;
			}
		}

		&__footer {
			border-top: 1px solid #e8e7e5;
		}
		&__toggles {
			display: flex;
			justify-content: flex-end;
			> .v-input {
				flex-grow: 0;
			}
		}
		&__checkbox {
			.v-input__slot {
				margin-right: 0;
				transform: scale(0.8);
			}
			&.v-input--is-disabled {
				.v-input--selection-controls__input {
					background-color: $color-CCCBC9;
					border-radius: 3px;
					height: 23px;
					width: 22px;
				}
				.v-icon {
					display: none;
				}
			}
			&--indeterminate {
				.v-icon {
					color: $color-orange;
				}
			}
		}
		&__zindex {
			z-index: 1 !important;
		}
		&__selection-cta {
			align-items: center;
			display: flex;
			flex-direction: row;
			.v-btn {
				color: $color-orange;
				height: 12px;
				text-decoration: underline;
				&:before {
					display: none;
				}
			}
		}
		&__selection-cta-divider {
			background-color: $color-CCCBC9;
			height: 17px;
			margin-right: 8px;
			width: 2px;
		}
	}
}
