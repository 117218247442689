$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";
#app {
	.scl-date-picker-modal {
		&__container {
			height: 450px;
			font-size: 16px;
		}
		&__container-layout {
			overflow-y: auto;
			> div {
				height: 55px;
			}
		}
		&__actions {
			position: absolute;
			bottom: 0;
			display: flex;
			justify-content: flex-end;
			width: 100%;
			@media (max-width: $md) {
				justify-content: center;
			}
		}
		&__date-picker {
			width: 320px;
			margin: 0 auto;
		}
	}
	.scl-date-picker-modal-component {
		overflow-x: hidden;
		.v-toolbar__content {
			height: 50px !important;
		}
		.underline {
			border-bottom: 1px solid #cccbc9;
		}
		.scl-dialog__toolbar-title {
			font-size: 18px;
		}
	}
}
