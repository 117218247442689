$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";


















































































































































































































































































































































































































.scl-form__select_small {
	display: inline-block;
}

.theme--light.v-icon.icon-orange {
	color: $color-orange !important;
}
#app .date-selector .scl-form__input input {
	margin-top: 13px !important;
}
.quote-id,
.scl-quotes-table__action-icon {
	color: #ff6105 !important;
}
.quote-dupicate {
	text-decoration: none;
}
.scl-table-filter__clear-btn {
	&:hover:before {
		background: transparent;
		opacity: 0;
	}
}
