$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";
#app {
	.scl-account-filter {
		width: 100%;
		height: 50px;
		background-color: $color-white;
		@media (max-width: $md) {
			height: auto;
		}

		.v-toolbar {
			height: 100%;
			background: none;
			box-shadow: 0px 2px 2px rgba(61, 57, 53, 0.08) !important;
			@media (max-width: $md) {
				padding: 15px;
			}
		}
		a {
			border-bottom: 1px solid $color-orange;
			color: $color-orange;
			
			#{$interact} {
				color: $color-orange-hover-light;
			}
		}
		.v-toolbar__content {
			height: 100% !important;
			padding-right: 27px;
			padding-left: 16px;
			flex-wrap: wrap;
		}
		&__toolbar-title {
			font-size: 14px;
			font-family: $font-name-klavika-medium;
		}
		&__filter-items {
			font-size: 16px;
		}
		&__filter-divider {
			width: 1px;
			height: 30px;
			background-color: $color-9B9A99;
		}
		&__filter-type-chip {
			height: 15px;
			font-size: 12px;
			border-radius: 5px;
		}
		&__filter-link {
			position: relative;
			text-decoration: none !important;
			&:before {
				position: absolute;
				bottom: 2px;
				width: 100%;
				height: 1px;
				background-color: $color-orange;
				content: "";
			}
			&.disabled {
				color: $color-grey2;
				cursor: defaut;
				pointer-events: none;
				&:before {
					background-color: $color-grey2;
				}
				i {
					color: $color-grey2 !important;
				}
			}
			#{$interact} {
				color: $color-orange-hover-light;
				i {
					color: $color-orange-hover-light !important;
				}
				&:before {
					color: $color-orange-hover-light;
					background-color: $color-orange-hover-light;
				}
			}
		}
	}
}
