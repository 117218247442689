$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";
#app {
	.scl-expected-offhire-modal {
		&__container-layout {
			overflow-y: auto;
		}
		&__actions {
			bottom: 0;
			display: flex;
			justify-content: flex-end;
			position: absolute;
			width: 100%;
			@media (max-width: $md) {
				justify-content: center;
			}
		}
		&__data-table {
			font-size: 16px;
			overflow-y: auto;
			thead {
				tr {
					height: 40px;
				}
				th {
					padding-left: 0;
				}
			}
			tbody tr td {
				padding-left: 5px;
			}
			tr td {
				font-size: 14px;
				padding: 10px;
			}
		}
		&__date-picker {
			border: 15px solid $color-CCCBC9;
			@media (min-width: $md) {
				float: right;
				width: 320px;
			}
		}
		&__action {
			display: flex;
			width: 100%;
			@media (min-width: $md) {
				float: right;
				width: 320px;
			}
			.v-btn {
				width: 49%;
				@media (min-width: $md) {
					width: 156px;
				}
			}
		}
	}
}
