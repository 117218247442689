$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";
#app {
	.scl-side-nav {
		background-color: #3d3935;
		position: absolute;
		z-index: 2;
		@media (max-width: $sm) {
			&:not(.mini-menu) {
				background-color: rgba($color-151011, 0.6);
				width: 100%;
			}
		}
		&.mini-menu {
			// border-right: 7px solid $color-E5E5E5;
			.scl-side-nav__drawer-btn {
				background: none;
			}
			.scl-side-nav__menulist-manage--label span {
				display: none;
			}
			@media (max-width: $sm) {
				border: none;
			}
		}

		.v-list__tile {
			height: 49px;
			padding-left: 18px;
			&__title {
				font-size: 16px;
				letter-spacing: 1px;
			}
			&:hover {
				color: $color-orange;
				.v-icon {
					color: $color-orange;
				}
			}
		}
		&__drawer {
			background-color: #4e4a46;
			display: flex;
			flex-direction: column;
			overflow-y: auto;
			@media (max-width: $sm) {
				&.v-navigation-drawer--mini-variant {
					width: 0 !important;
				}
			}
			@media (max-width: $md) {
				margin-top: 60px !important;
				height: calc(100% - 60px) !important;
			}
		}
		&__drawer-btn {
			background-color: #4e4a46;
			height: 64px;
			position: absolute;
			top: -64px;
			transition: background 0.3s;
			width: 60px;
			z-index: 1;
			.v-icon {
				color: $color-white;
				text-align: center;
			}

			@media (max-width: $md) {
				top: 0;
			}
		}
		&__menulist-manage {
			border-bottom: 1px solid #e8e7e5;
			height: 100%;
			&--label {
				border-top: 1px solid #e8e7e5;
				color: #b6b5b3;
				text-transform: uppercase;
			}
		}
		&__menulist-bottom {
			margin-top: auto;
			width: 100%;
		}
		&__logout {
			border-top: 1px solid #e8e7e5;
			bottom: 0;
			position: absolute;
			width: 100%;
			.v-icon {
				font-size: 24px;
			}
		}
		&__list {
			background-color: $color-4E4A46;
			.v-icon {
				text-align: center;
				&:before {
					display: block;
					width: 24px;
				}
			}
			.active {
				color: $color-orange;
				.v-icon {
					color: $color-orange;
				}
			}
		}
	}
}
