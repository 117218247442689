$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";
#app {
	.scl-snackbar {
		.v-snack {
			&__wrapper {
				border-radius: 4px;
				overflow: hidden;
			}
			&__content {
				border-left: 5px solid;
				justify-content: flex-start;
				min-height: 80px;
			}
			.v-btn {
				position: absolute;
				right: 10px;
				top: 5px;
			}
		}
		a {
			color: $color-white;
		}
		&__theme-success {
			.v-snack__content {
				border-left: 5px solid $color-green;
			}
		}
		&__theme-error {
			.v-snack__content {
				border-left: 5px solid $color-red;
			}
		}
		.v-closeBtn {
			position: absolute;
			right: 10px;
			top: 5px;
		}
	}
}
