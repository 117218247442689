$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";
#app {
	.scl-account-access-modal {
	}
	.scl-account-access-modal-component {
		min-height: 80%;
		height: 100%;
		overflow: hidden;
		.v-form {
			overflow-y: scroll;
			position: absolute;
			width: 100%;
			height: 80%;
			top: 20%;
			@media (min-width: $md) {
				height: 90%;
				top: 10%;
			}
		}
		.topline {
			border-top: 1px solid #ccc;
		}
	}
}
