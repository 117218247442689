$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";
#app {
	#pie-chart {
		height: 195px !important;
	}
}

.chartjs-tooltip-pie {
	opacity: 1;
	position: absolute;
	background-color: #3d3935;
	color: white;
	border-radius: 4px;
	-webkit-transition: all 0.1s ease;
	transition: all 0.1s ease;
	pointer-events: none;
	-webkit-transform: translate(-50%, 0);
	transform: translate(-50%, 0);
	padding: 4px;
	&:before {
		content: "";
		width: 0;
		height: 0;
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;
		border-top: 6px solid #3d3935;
		position: absolute;
		bottom: -5px;
		transform: translateX(-50%);
		left: 50%;
	}
}
