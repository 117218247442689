$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";
#app {
	.scl-group-list {
		&__password-mask {
			margin-right: 6px;
		}
		&__chip {
			padding: 20px 13px;
		}
		&__status-item {
			position: relative;
		}
		&__password-button {
			button {
				position: absolute;
				bottom: 0;
				top: 0;
				right: 0;
				margin: auto;
			}
		}
	}
}
