$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";




































































































































































































































































































































































.vue-number-input {
	position: relative;
	box-sizing: border-box;
	height: 48px;

	&.small {
		height: 20px;
		font-size: 12px;

		.numeric-input {
			padding: 2px 1.4rem;
		}
	}

	&.large {
		height: 42px;
		font-size: 34px;

		.numeric-input {
			padding: 2px 2.8rem;
		}
	}

	.numeric-input {
		height: inherit;
		padding: 2px 2rem;
		box-sizing: border-box;
		font-size: inherit;
		background: #f8f8f8;
		border: 1px solid #c8c8c8;
		display: block;
		line-height: 1.8rem;
		transition: all 0.1s ease 0s;
		width: 100%;
		-moz-appearance: textfield !important;

		&:read-only {
			border: 0;
		}
		&:focus {
			outline: none;
			border-color: #409eff;
		}
	}
}

.numeric-input::-webkit-inner-spin-button,
.numeric-input::-webkit-outer-spin-button {
	-webkit-appearance: none !important;
	margin: 0 !important;
}

.vue-number-input button {
	position: absolute;
	width: 1.8rem;
	padding: 1.2rem;
	margin: 0;
	cursor: pointer;
	text-align: center;
	transition: all 0.1s ease 0s;
}
.vue-number-input button:focus {
	outline: none !important;
}
.vue-number-input.small button {
	width: 20px;
}
.vue-number-input.large button {
	width: 40px;
}
.vue-number-input button:hover {
	background: rgba(0, 0, 0, 0.1);
}
.vue-number-input button:active {
	box-shadow: rgba(0, 0, 0, 0.1) 0 1px 3px inset, rgba(255, 255, 255, 0.5) -1px -1px 4px inset;
}
.vue-number-input button:disabled {
	opacity: 0.5;
	box-shadow: none;
	cursor: not-allowed;
}
.vue-number-input .numeric-input.no-control {
	padding: 2px 5px;
}
.vue-number-input .btn-increment {
	right: 0;
	top: 0;
	bottom: 0;
}
.vue-number-input .btn-increment .btn-icon {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}
.vue-number-input .btn-increment .btn-icon:before {
	display: inline-block;
	visibility: visible;
	content: '';
	background-image: url('/assets/img/plus.svg');
	background-repeat: no-repeat;
	background-size: 65% 65%;
	background-position: center;
	width: 100%;
	height: 100%;
}
.vue-number-input .btn-increment .btn-icon:after {
	position: absolute;
	visibility: hidden;
	content: '';
}
.vue-number-input .btn-decrement {
	left: 0;
	top: 0;
	bottom: 0;
	border-radius: 2px;
	border-width: 1px;
}
.vue-number-input .btn-decrement .btn-icon {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}
.vue-number-input .btn-decrement .btn-icon:before {
	display: inline-block;
	visibility: visible;
	content: '';
	background-image: url('/assets/img/minus.svg');
	background-repeat: no-repeat;
	background-size: 65% 65%;
	background-position: center;
	width: 100%;
	height: 100%;
}
.vue-number-input .btn-decrement .btn-icon:after {
	visibility: hidden;
	content: '';
	clear: both;
	height: 0;
}
.vue-number-input.updown .numeric-input {
	padding: 5px 2rem 5px 5px;
}
.vue-number-input.updown .btn-increment {
	right: 0;
	top: 0;
	bottom: 50%;
	border-radius: 2px 2px 0 0;
	border-width: 1px 1px 0;
}
.vue-number-input.updown .btn-increment .btn-icon {
	top: 50%;
	left: 50%;
	width: 0;
	height: 0;
	border-width: 0 0.45rem 0.45rem;
	border-color: transparent transparent #111;
	border-style: solid;
	margin: -0.25rem 0 0 -0.4rem;
}
.vue-number-input.updown .btn-increment .btn-icon::before {
	visibility: hidden;
	display: block;
	content: '';
	clear: both;
	height: 0;
}
.vue-number-input.updown .btn-decrement .btn-icon::before {
	content: '';
}
.vue-number-input.updown .btn-increment .btn-icon::after {
	visibility: hidden;
	display: block;
	content: '';
	clear: both;
	height: 0;
}
.vue-number-input.updown .btn-decrement {
	right: 0;
	top: 50%;
	bottom: 0;
	left: auto;
	border-radius: 0 0 2px 2px;
	border-width: 0 1px 1px;
}
.vue-number-input.updown .btn-decrement .btn-icon {
	top: 50%;
	left: 50%;
	width: 0;
	height: 0;
	border-width: 0.45rem 0.45rem 0;
	border-color: #111 transparent transparent;
	border-style: solid;
	margin: -0.25rem 0 0 -0.4rem;
}
