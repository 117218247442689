$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";


























































.scl-account-ageing-card {
	width: auto;
	overflow: hidden;
	box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
	&__indicator {
		background-color: $color-grey7;
	}
	&__details {
		color: $color-3D3935;
	}
}
