$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";
#app {
	.scl-footer-list-large {
		background-color: $primary-color;
		padding: 20px 0;
		&__title {
			color: $color-white;
			font-size: 16px;
			letter-spacing: 1.5px;
			padding-top: 15px;
			text-transform: uppercase;
		}
		&__list {
			background: none;
			color: $color-white;
			font-size: 13px;
			letter-spacing: 1px;
			.v-list__tile,
			a {
				color: $color-white;
				font-size: 13px;
				height: auto;
				line-height: 25px;
				padding: 5px 0;
				#{$interact} {
					color: #adadad;
					cursor: pointer;
				}
			}
		}
		&__list--full {
			display: flex;
			flex-flow: row wrap;
			> div {
				flex: 0 0 100%;
			}
		}
		&__call {
			margin-bottom: 26px;
		}
		&__call-icon {
			color: $color-orange;
			font-size: 16px;
			margin-right: 10px;
		}
		&__call-title {
			color: $color-white;
			font-family: $font-name-klavika-bold;
			font-size: 27px;
		}
		.scl-button {
			display: block;
		}
		.scl-button--white {
			border: 2px solid $color-white;
			color: $color-white;
			margin-top: 16px;
		}
		@media (min-width: $sm) {
			&__list--full {
				> div {
					flex: 0 0 calc(100% / 3);
				}
			}
		}
		@media (min-width: $md) {
			&__list--full {
				> div {
					flex: 0 0 calc(100% / 4);
				}
			}
		}
		@media (min-width: $lg) {
			&__list--full {
				> div {
					flex: 0 0 calc(100% / 6);
				}
			}
		}
	}
}
