$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";
#app {
	.scl-personal-detail-form {
		&.scl-form {
			.v-select {
				.v-icon {
					font-size: 18px;
				}
			}
		}
		.container.fluid {
			max-width: 100% !important;
		}
	}
}
