$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";
#app {
	.scl-update-user-access-modal {
		&__container {
			font-size: 16px;
			height: auto;
			@media (min-width: $md) {
				height: auto;
			}
		}
		&__container-layout {
			overflow-y: auto;
		}
		&__actions {
			bottom: 0;
			display: flex;
			justify-content: flex-end;
			position: absolute;
			width: 100%;
			@media (max-width: $md) {
				justify-content: center;
			}
		}
		&__divider {
			min-width: 100%;
		}
	}
	.scl-user-access-form .v-input {
		margin-left: 14px;
	}
}
