$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";
#app {
	.scl-confirmation-detail {
		width: 400px;
		&__card {
			border: 1px solid $color-black;
			ul {
				list-style: none;
			}
		}
		.v-list {
			&__tile {
				height: auto;
				padding-bottom: 5px;
			}
		}
	}
}
