$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";
#app {
	.scl-contact-list {
		.container.fluid {
			max-width: 100% !important;
		}
		.underline {
			border-bottom: 1px solid #cccbc9;
		}
		&__contact {
			display: flex;
		}
	}
}
