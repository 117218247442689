$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";
#app {
	.scl-exception-accordion {
		.container.fluid {
			max-width: 100% !important;
		}
		.v-expansion-panel {
			box-shadow: none;
			&__container {
				background-color: $color-E5E5E5;
				margin-bottom: 7px;
			}
			&__header {
				font-size: 16px;
				font-weight: 700;
				padding-left: 15px;
				padding-right: 15px;
			}
			&__body {
				.row {
					border: 1px solid $color-CCCBC9;
				}
			}
		}
		.v-select {
			width: 100%;
			background-color: #e8e7e5;
			@media (min-width: $md) {
				width: 145px;
			}
			&__selections {
				padding-top: 0;
			}
			.v-input {
				&__slot {
					border-radius: 0;
					min-height: 50px;
				}
			}
			.v-label {
				top: 13px;
			}
		}
		&__group-label {
			font-size: 24px;
		}
	}
}
