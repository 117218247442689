$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";
#app {
	.scl-quote-confirmation {

		&__content {
            margin: gutter-size(6);
            padding: gutter-size(4);
            background: $color-white;
			a {
				color: $color-orange;
			}
		}
        .scl-new-header {
            padding-bottom: 0;
        }
        .scl-quotes-header__subtitle p {
            margin: 0 !important;
        }
	}
}
