$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";
#app {
	.scl-bottom-sheet-hire-mobile-menu {
		&__title-select {
			background-color: $color-E5E5E5;
			label {
				color: $color-9B9A99;
				font-size: 18px;
			}
			.v-input__slot {
				border-color: $color-CCCBC9;
				min-height: 50px;
			}
		}
		&__date-select {
			background-color: $color-E5E5E5;
			border: 1px solid $color-CCCBC9;
			&.v-input {
				padding: 13px 9px 0px;
			}
			.v-input__slot:before {
				display: none;
			}
		}
		&__footer-link {
			div:last-child {
				display: flex;
				justify-content: flex-end;
			}
			div:nth-child(2),
			div:nth-child(3) {
				display: flex;
				justify-content: center;
				.v-btn {
					width: 70px;
				}
			}
			.v-btn {
				align-items: flex-start;
				display: flex;
				.v-ripple__container {
					display: none;
				}
			}
		}
		&__footer-links {
			.v-btn__content {
				display: flex;
				flex-direction: column;
			}
			// .v-btn {
			// 	width: 60%;
			// }
			p {
				white-space: break-spaces;
			}
		}
	}
}
