$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";
#app {
	.scl-update-hire-modal {
		&__container {
			height: 210px;
			font-size: 16px;
		}
		&__container-layout {
			overflow-y: auto;
			height: 140px;
			> div {
				height: 55px;
			}
		}
		&__actions {
			position: absolute;
			bottom: 0;
			display: flex;
			justify-content: flex-end;
			width: 100%;
			@media (max-width: $md) {
				justify-content: center;
			}
		}
	}
	.scl-update-hire-modal-component {
		.underline {
			border-bottom: 1px solid #cccbc9;
		}
	}
}
