$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";
#app {
	.scl-card-icon {
		&__card {
			background: none;
			display: flex;
			flex-direction: column;
		}
		&__icon {
			font-size: 55px;
			margin: 20px auto;
		}
		&__container {
			padding: 0;
		}
		&__title {
			color: $primary-color;
			font-family: $font-name-klavika-bold;
			font-size: 16px;
			line-height: 22px;
			margin-bottom: 16px;
			text-align: center;
			text-transform: uppercase;
		}
		&__copy {
			color: $primary-color;
			font-family: $font-name-klavika-medium;
			font-size: 14px;
			letter-spacing: 0.5px;
			line-height: 24px;
			margin: 0;
			text-align: center;
		}
	}
}
