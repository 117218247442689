$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";
#app {
	.scl-confirmation-modal {
		&__container {
			font-size: 16px;
			height: 70%;
			pre {
				font-family: $font-name-klavika-medium;
				white-space: pre-wrap;
				word-wrap: break-word;
				@media (max-width: $md) {
					padding: 0 12px;
				}
			}
			.v-datatable {
				td {
					font-size: 17px;
				}
			}
		}
		&__container-layout {
			overflow-y: auto;
		}
		&__actions {
			bottom: 0;
			display: flex;
			justify-content: flex-end;
			position: absolute;
			width: 100%;
			@media (max-width: $md) {
				justify-content: center;
			}
		}
		&__footnote {
			color: $color-grey2;
		}
	}
	.scl-confirmation-modal-component {
		height: 460px;
	}
}
