$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";
#app {
	.scl-card-toggle {
		background-color: $color-grey7;
		width: 100%;
		.v-input--switch__track {
			background-color: $color-grey4 !important;
		}
		.v-input--is-label-active {
			.v-input--switch__track {
				background-color: $color-3D3935 !important;
			}
		}
		.v-input--switch__thumb {
			background-color: $color-white;
		}
	}
}
