$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";
#app {
	.scl-contact-card {
		border: 1px solid #817e79;
		width: 100%;
		@media (min-width: $md) {
			width: 240px;
		}
		a {
			color: $color-orange;
			#{$interact} {
				color: $color-orange-hover-light;
			}
		}
		&__title {
			background-color: #817e79;
			color: #fff;
			letter-spacing: 0.5px;
		}
		&__footer {
			border-top: 1px solid #817e79;
		}
	}
}
