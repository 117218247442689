$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";
#app {
	.scl-card-solid {
		background-color: $color-white;
		&__card {
			position: relative;
		}
		&__img {
		}
		&__panel {
			align-items: center;
			background-color: $color-orange;
			bottom: 0;
			display: flex;
			justify-content: center;
			left: 0;
			position: absolute;
			right: 0;
			top: 0;
			transition: background-color timing(faster) $easing;
			&:hover {
				background-color: rgba($color-black, 0.5);
			}
		}
		&__panel-container {
			padding: 40px;
			text-align: center;
		}
		&__icon {
			font-size: 55px;
			margin-bottom: 20px;
		}
		&__title {
			color: $color-white;
			font-family: $font-name-klavika-medium;
			font-size: 23px;
			font-weight: 400;
			letter-spacing: 1.5px;
			line-height: 1em;
			text-align: center;
			text-transform: uppercase;
		}
	}
}
