$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";
/*!
-----------------------------------------------------------
app.scss
-----------------------------------------------------------
*/

// core architecture of the SCSS utilises ITCSS methodology
// order of CSS matters, use this file as the guide to understand where to import your css

// 1.	Global Helpers [imported in vue.config.js]
//		imports the global helper layers first (settings & tools)
//		we make these layers available to every component by injecting them using sass-loader
// 		these files should never output any css or they will be duped in every chunk
// 		only variables, mixins, functions, animations etc.

// 2. 	Vendor CSS [imported in main.js]
// 		vendor libraries are imported in main.js first
// 		typically vendor libraries use !default flags in their variables
// 		so we will be able to override vendor variables in layer 1 above
// 		if this fails, you may add a vendor overrides partials in this file

// 3. 	app css [imported in main.js]
//		styling that applies globally across the app/site should be included here
// 		this includes the (generic, elements, objects) layers below
// 		it is imported in main.js so it only loads once

// 4. 	component css [imported in their respective scss file]
// 		self explanatory

// settings
// -----------------------------------------------------------
@import "settings/variables";

// tools
// -----------------------------------------------------------
@import "tools/mixins";
@import "tools/utilities";
@import "tools/typography";

// vendor overrides
// -----------------------------------------------------------

// generic
// -----------------------------------------------------------
@import "generic/container";
@import "generic/spacer";
@import "generic/body";
@import "generic/html";

// elements
// -----------------------------------------------------------
@import "elements/transitions";

// objects
// -----------------------------------------------------------

// components
// -----------------------------------------------------------
// component CSS is written & imported in it's individual file
@import "components/dialog";
@import "components/form";
@import "components/wrapper";
@import "coates/portals";
