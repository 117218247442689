$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";
#app {
	.scl-email-domain-form {
		.container.fluid {
			max-width: 100% !important;
		}
		input {
			margin-top: 0;
		}
		&__line-item {
			display: flex;
			border-bottom: 1px solid $color-CCCBC9;
			.v-input--is-disabled input {
				color: $color-9B9A99;
			}
		}
		&__input-field {
			max-width: 400px;
		}
		&__empty-row:hover {
			background-color: transparent;
		}
		&__email-domain {
			width: 82%;
		}
		&__hidden-header {
			display: none;
		}
		&__action-icon {
			color: $color-orange;
		}
	}
}
