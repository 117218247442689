$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";
#app {
	.scl-file-upload {
		ul {
			list-style: none;
		}
		.file-uploads {
			color: $color-orange;
			text-decoration: underline;
		}
	}
}
