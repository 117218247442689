$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";
#app {
	.scl-page-header {
		background-color: $color-E5E5E5;
		&__title {
			font-size: 32px;
			text-transform: capitalize;
		}
	}
}
