$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";
#app {
	.scl-site-access-accordion {
		.container.fluid {
			max-width: 100% !important;
		}
		.v-expansion-panel {
			border-radius: 4px;
			border-top: 1px solid $color-CCCBC9;
			box-shadow: none;
			&__container {
				background-color: $color-white;
				border: 1px solid $color-CCCBC9;
				border-radius: 4px;
				margin-bottom: 10px;
			}
			&__header {
				font-size: 16px;
				font-weight: 700;
				padding-left: 15px;
				padding-right: 15px;
			}
			&__body {
				.row {
					border: 1px solid $color-CCCBC9;
				}
			}
			&__header__icon {
				.v-icon {
					color: $color-orange;
				}
			}
		}
		&__note {
			background-color: $color-E5E5E5;
			border-radius: 4px;
		}
		&__expansion-title {
			background-color: $color-CCCBC9;
			height: 56px;
		}
	}
}
