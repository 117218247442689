$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";
#app {
	.scl-subscribe-form {
		background-color: #eaeaea;
		&__container {
			align-items: center;
			display: flex;
			height: 100%;
			p {
				color: $primary-color;
				font-family: $font-name-klavika-medium;
				font-weight: 400;
				letter-spacing: 0.5px;
				line-height: 1.4;
				margin: 0;
			}
		}
		&__icon {
			color: #5b5b5b;
			float: left;
			font-size: 24px;
			margin-right: 10px;
		}
		.scl-input {
			margin-right: 20px;
			.v-input__slot {
				background-color: $color-white;
				border: 1px solid #cbcbce;
			}
			input {
				line-height: 48px;
				max-height: 48px;
				padding: 0 8px;
			}
		}
		.scl-button {
			margin-top: -6px;
		}
	}
}
