@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

#app {
	opacity: 0;
	animation: fadeIn timing(fast) $easing forwards;
}
