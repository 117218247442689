$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";
#app {
	.scl-quote-contact-modal {
		&__component {
		.v-toolbar {
			background-color: $color-white;
		}
		.scl-form__label {
			font-family: inherit;
		}
		.scl-button {
			width: 100%;
		}
		.v-text-field__details {
			padding: 0;
		}
		.error--text {
			padding: gutter-size(1) 0 gutter-size(2);
			color: #E00000 !important;
			font-size: $font-size-base;

		}
	}
		&__title {
			font-size: $font-size-lg;
			margin-bottom: gutter-size(2);
		}
		&__form {
			max-width: 500px;
			margin: 0 auto;
			.scl-form__input {
				.v-input__slot {
					background: $color-white !important;
				}
			}
		}
		
		&__textarea {
			.v-input__slot {
				background: $colour-input !important;
				border: 1px solid $colour-border;
				border-radius: 0;
			}
			textarea::-webkit-resizer {
				background-image: url("/assets/img/resizer.png");
				background-position: top left;
				background-size: 70%;
				background-repeat: no-repeat;
			}
		}
		&__actions {
			display: block;
			padding-bottom: 60px;
			p {
				padding: gutter-size(6) gutter-size(3) 0;
				color: #E00000;
			}
		}
				
	}
}
