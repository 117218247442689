$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";
#app {
	.scl-threshold-modal {
		h3 {
			font-size: 20px;
			font-weight: 700;
		}
		&__container {
			height: auto;
			font-size: 16px;
			@media (min-width: $md) {
				height: auto;
			}
		}
		&__container-layout {
			overflow-y: auto;
		}
		&__actions {
			position: absolute;
			bottom: 0;
			display: flex;
			justify-content: flex-end;
			width: 100%;
			@media (max-width: $md) {
				justify-content: center;
			}
		}
		&__component {
			.scl-form__step-number {
				max-width: 145px;
				border: 2px solid black;
				.v-icon {
					font-size: 18px;
					color: black;
				}
				.v-input__control {
					background-color: $color-grey7;
					border-left: 2px solid black;
					border-right: 2px solid black;
				}
			}
			.underline {
				border-bottom: 1px solid #cccbc9;
			}
			.v-divider {
				min-width: 100%;
			}
			.v-card__actions {
				@media (max-width: $sm) {
					display: flex;
					flex-direction: column-reverse;
					.v-btn {
						width: 100%;
					}
				}
			}
			.v-toolbar__content {
				@media (max-width: $sm) {
					height: 90px !important;
				}
			}
		}
		&__selection-item {
			display: flex;
			flex-direction: column;
			@media (min-width: $md) {
				flex-direction: row;
				align-items: center;
			}
			.scl-form__select {
				@media (min-width: $md) {
					max-width: 45%;
				}
			}
		}
	}
}
