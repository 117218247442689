$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";
#app {
	.scl-card-image {
		background-color: $color-white;
		&__card {
			&.align-bottom {
				.v-responsive__content {
					justify-content: flex-end;
				}
			}
		}
		&__img {
			padding: 32px 24px;
			position: relative;
		}
		.v-responsive__content {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}
		&__img-overlay {
			background: linear-gradient(180deg, transparent, $color-black);
			bottom: 0;
			left: 0;
			position: absolute;
			right: 0;
			top: 0;
		}
		&__title-container {
			position: relative;
		}
		&__title {
			color: $color-white;
			font-family: $font-name-klavika-bold;
			font-size: 27px;
			line-height: 1em;
			text-transform: uppercase;
		}
		&__subtitle {
			color: $color-white;
			font-family: $font-name-klavika-medium;
			font-size: 23px;
			height: 50px;
			line-height: 1em;
			margin-top: 10px;
		}
		.scl-button {
			align-self: flex-start;
			border-color: $color-white;
			color: $color-white;
			font-size: 15px;
			line-height: 38px;
			margin-top: 21px;
			padding: 0 16px;
		}
	}
}
