$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";
#app {
	.scl-enquiry-modal {
		.scl-form__checkbox {
			border: 2px solid $primary-color;
			margin: 0 0 30px;
			padding: 40px 15px 15px;
			&.v-input--is-dirty {
				background-color: $primary-color;
				label {
					color: $color-white;
				}
			}
		}
		&__product {
			background-color: #eaeaea;
			padding: 20px;
		}
		&__product-container {
			align-items: center;
			background-color: $color-white;
			display: flex;
			flex-direction: row;
			margin-top: 20px;
			padding: 10px;
			span {
				font-family: $font-name-klavika-medium;
				font-size: 14px;
				font-weight: 400;
				letter-spacing: 0.5px;
				line-height: 24px;
				margin-left: 10px;
			}
		}
		&__product-img {
			max-width: 85px;
		}
	}
}
