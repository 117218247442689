$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";









































































































































$min-fixed-layout: 1180px;
$max-fixed-layout: 1400px;

.scl-bar-chart-table {
	&__container {
		::v-deep table.v-table {
			tbody td,
			tfoot td {
				padding: 0 12px;
				font-size: 16px !important;
			}
			@media (min-width: $min-fixed-layout) {
				table-layout: fixed;
			}
		}
		thead {
			tr {
				height: 90px;
			}
			th {
				min-width: 70px;
				height: 90px;
				padding: 0 !important;

				@media (max-width: $md) {
					min-width: 55px;
				}

				@media (max-width: $sm) {
					min-width: 100px;
				}
			}
		}
	}
	&__footer {
		&--highlight {
			background-color: $color-grey7;
		}
	}

	&__header-chart {
		padding: 0;
		&--bar {
			position: absolute;
			bottom: 0;
			left: 50%;
			width: 50%;
			background-color: $color-orange;
			transform: translateX(-50%);

			@media (max-width: $md) {
				width: 50%;
			}

			@media (max-width: $sm) {
				width: 60%;
			}
		}
		@media (max-width: $sm) {
			.body-1 {
				font-size: 12px !important;
			}
		}
	}
	&__header-chart-container {
		position: relative;
		height: 430px;
		border-bottom: 1px solid $color-grey4;

		@media (max-width: $md) {
			height: 320px;
		}

		@media (max-width: $sm) {
			height: 200px;
		}
	}
	&__item-row {
		display: flex;
		flex-flow: row wrap;

		.column-sitename {
			flex-basis: 100%;
			padding: 8px 8px 0;
			color: $color-grey3;
		}
		.column-master-account {
			flex-basis: 100%;
			padding: 24px 8px 0;
			color: $accent;
			font-weight: $font-weight-bold;
			border-bottom: 2px solid $color-grey4;
			font-size: 18px;
		}
		.column-site-values {
			flex-basis: 100%;
			padding: 8px 8px 0;
		}
		.column-amount {
			flex: 1 1 0;
			padding: 0 8px 8px;
			text-align: right;

			@media (min-width: $min-fixed-layout) and (max-width: $max-fixed-layout) {
				font-family: 'Arial Narrow';
			}
		}
	}
}
