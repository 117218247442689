$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";
#app {
	.scl-table-filter {
		.fluid {
			max-width: none;
		}

		.scl-form__select {
			@media (min-width: $md) {
				width: 175px;
			}
			.v-select__selections {
				padding-top: 0;
			}
		}
		.v-text-field__slot {
			input {
				margin-top: 0;
			}
		}
		.v-input__slot {
			min-height: 50px;
			label {
				color: #9b9a99;
			}
		}
		.v-list__title {
			&__content {
				color: #9b9a99;
			}
		}
		&__search-chips {
			.v-chip__content {
				color: $color-white;
				display: flex;
				padding-left: 6px;
				padding-right: 8px;
				strong {
					color: $color-white;
				}
			}
			.v-chip__close {
				color: $color-white;
				cursor: pointer;
				margin-left: 0;
				.v-icon {
					display: none;
				}
				&:before {
					content: "+";
					font-size: 30px;
					margin-left: 7px;
					transform: rotate(45deg);
				}
			}
		}
		&__search-filter {
			@media (min-width: $lg) {
				min-width: 520px;
				width: auto;
			}
			.v-input__append-inner {
				margin-top: 0 !important;
			}
			.v-icon {
				color: $color-orange;
				font-size: 22px;
			}
			.v-chip {
				background-color: $color-CCCBC9;
				display: none;
				@media (min-width: $md) {
					display: flex;
					margin-top: -7px;
				}
			}
		}
		&__icon-group {
			.v-btn {
				height: 48px;
				width: 48px;
				&.border {
					border: 2px solid $primary-color;
					border-radius: 0;
				}
			}
		}
		&__clear-btn {
			align-items: center;
			text-decoration: none;
		}
		.divider-vertical {
			background-color: $color-CCCBC9;
			height: 45px;
			width: 1px;
		}
	}
}
