$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";
#app {
	.scl-hire-header {
		font-family: $font-name-klavika-medium;
		.container.fluid {
			max-width: 100% !important;
		}
		.link-divider {
			border-right: 1px solid $color-9B9A99;
			margin-right: 22px;
			padding-right: 22px;
			height: 25px;
			display: none;
			@media (min-width: $md) {
				display: block;
			}
		}
		&__title {
			font-size: 24px;
		}
		&__subtitle {
			display: flex;
			font-size: 16px;
			flex-direction: column;
			@media (min-width: $md) {
				flex-direction: row;
			}
		}
		&__icon {
			padding: 10px;
			border: 2px solid $primary-color;
		}
	}
}
