$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";
#app {
	.scl-hire-table-header {
		font-family: $font-name-klavika-medium;
		background-color: $color-E5E5E5;
		.container.fluid {
			max-width: 100% !important;
		}
		.link-divider {
			border-right: 1px solid $color-9B9A99;
			margin-right: 22px;
			padding-right: 22px;
			height: 25px;
			display: none;
		}
		.divider-vertical {
			width: 1px;
			height: 45px;
			background-color: $color-CCCBC9;
		}
		&__title {
			font-size: 24px;
			// @media (max-width: $sm) {
			// 	width: 110px;
			// }
			&--filter-label {
				color: $color-9B9A99;
			}
		}
		&__subtitle {
			display: flex;
			font-size: 16px;
			flex-direction: column;
			@media (min-width: $md) {
				flex-direction: row;
			}
		}
		&__icon-group--primary {
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			.v-btn {
				width: 48px;
				height: 48px;
				&.border {
					border: 2px solid $primary-color;
					border-radius: 0;
				}
			}
		}
		&__icon--primary {
			padding: 10px 0;
		}
		&__icon-group {
			display: flex;
			flex-direction: row;
			justify-content: flex-end;

			@media (max-width: $sm) {
				justify-content: flex-start;
			}

			.v-btn {
				width: 48px;
				height: 48px;
				margin: 0 gutter-size(2);

				@media (max-width: $sm) {
					margin: gutter-size(2);

					&:first-child {
						margin-left: 0;
					}
				}

				&.border {
					border: 2px solid $color-orange;
					border-radius: 0;
				}
				&.fit-content {
					width: fit-content;
				}
			}
		}
		&__icon {
			padding: 10px 0;
			font-size: 30px;
    		font-weight: 100;

			&--help {
				height: 48px;
				font-size: 24px;
				border: 2px solid $color-orange;
				border-radius: 50%;
				padding: 10px 13px;
			}
		}
		&__title-filter {
			display: flex;
			align-items: center;
		}
		&__title-select {
			.v-input__slot {
				font-weight: 900;
				width: 430px;
				color: $color-9B9A99;

				@media (max-width: $sm) {
					margin: gutter-size(2);
					width: 100%;
				}
			}
		}
	}
}
