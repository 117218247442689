$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";
#app {
	.scl-card {
		&__card {
			background-color: #eaeaea;
			box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
		}
		&__img {
			opacity: 1;
			transition: opacity timing(faster) $easing;
			#{$interact} {
				opacity: 0.8;
			}
		}
		&__title-container {
			padding: 16px;
			p {
				color: $primary-color;
				font-family: $font-name-klavika-medium;
				font-size: 17px;
				font-weight: 400;
				letter-spacing: 0.5px;
				margin-bottom: 16px;
			}
		}
		&__title-anchor {
			display: block;
			padding: 0;
			text-decoration: none;
			width: 100%;
		}
		&__title {
			color: $primary-color;
			font-family: $font-name-klavika-bold;
			font-size: 15px;
			letter-spacing: 1px;
			line-height: 22px;
			text-transform: uppercase;
			#{$interact} {
				color: $color-orange;
				cursor: pointer;
			}
			&.with-description {
				color: $color-orange;
				#{$interact} {
					color: $primary-color;
				}
			}
		}
		&__actions {
			padding: 0 16px 16px;
		}
		&__accordion {
			box-shadow: none;
		}
		&__accordion-container {
			background: none;
		}
		.v-expansion-panel__header {
			color: $color-orange;
			font-family: $font-name-klavika-medium;
			font-size: 12px;
			font-weight: 700;
			letter-spacing: 1px;
			line-height: 15px;
			min-height: 38px;
			padding: 0;
			text-transform: uppercase;
		}
		&__accordion-item {
			background: none;
			box-shadow: none;
		}
		&__accordion-item-container {
			padding: 0;
		}
		&__accordion-anchor {
			color: $primary-color;
			display: block;
			font-family: $font-name-klavika-medium;
			font-size: 15px;
			line-height: 24px;
			padding: 5px 0;
			text-decoration: none;
			#{$interact} {
				text-decoration: underline;
			}
		}
	}
}
