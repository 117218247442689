$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";
#app {
	.scl-resend-email-modal {
		&__container {
			height: auto;
			font-size: 16px;
			@media (min-width: $md) {
				height: auto;
			}
		}
		&__container-layout {
			overflow-y: auto;
		}
		&__actions {
			position: absolute;
			bottom: 0;
			display: flex;
			justify-content: flex-end;
			width: 100%;
			@media (max-width: $md) {
				justify-content: center;
			}
		}
		&__component {
			.underline {
				border-bottom: 1px solid #cccbc9;
			}
		}
		&__selection-item {
			display: flex;
			flex-direction: column;
			@media (min-width: $md) {
				flex-direction: row;
				align-items: center;
			}
			.scl-form__select {
				@media (min-width: $md) {
					max-width: 45%;
				}
			}
		}
	}
}
