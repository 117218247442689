$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";
#app {
	.scl-manage-users-page-header {
		height: 75px;
		width: 100%;
		background-color: $color-E5E5E5;
		.v-toolbar {
			height: 100%;
			background: none;
		}
		.v-toolbar__content {
			height: 100% !important;
		}
	}
}
