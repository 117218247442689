$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";
#app {
	.scl-threshold-setting {
		.container.fluid {
			max-width: 100% !important;
		}
		&__title {
			font-size: 24px;
		}
		&__chip {
			padding: 20px 13px;
		}
	}
}
