#app {
	.container {
		max-width: 100%;
	}
	@media (min-width: $sm) {
		.container {
			max-width: 100%;
		}
	}
	@media (min-width: $md) {
		.container {
			max-width: 100%;
		}
	}
	@media (min-width: $lg) {
		.container {
			max-width: 1280px;
		}
	}
	@media (min-width: $xl) {
		.container {
			max-width: 1280px;
		}
	}
}
