$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";
#app {
	.scl-email-domain-modal {
		&__container {
			font-size: 16px;
			height: auto;
			@media (min-width: $md) {
				height: auto;
			}
			input {
				margin-top: 0;
			}
		}
		&__actions {
			bottom: 0;
			display: flex;
			justify-content: flex-end;
			position: absolute;
			width: 100%;
			@media (max-width: $md) {
				justify-content: center;
			}
		}
		&__component {
			.underline {
				border-bottom: 1px solid $color-CCCBC9;
			}
		}
		&__listing-header {
			div {
				background-color: $color-orange;
				color: $color-white;
			}
		}
		&__listing-table {
			@media (max-width: $sm) {
				overflow-x: scroll;
			}
		}
		&__listing {
			> div {
				background-color: $color-grey7;
			}
		}
	}
}
