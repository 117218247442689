$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";
#app {
	.scl-pricing-table {
		&__price-column {
			background-color: rgba($color: #e5e5e5, $alpha: 0.4);
		}
		&__default-text {
			display: inline-block;
			margin: 0;
			width: 100%;
			line-height: 42px;
			vertical-align: middle;
			background-color: rgba($color: #eab100, $alpha: 0.4);
			padding-left: 30px;
			border-radius: 5px;
		}

		&__pricebook-statement {
			margin-left: auto;
			margin-right: 10px;
			flex: initial;
			font-size: 16px;
			color: rgba(0, 0, 0, 0.54);
		}

		.container.fluid {
			max-width: 100% !important;
		}

		a {
			&.grey-link {
				color: $color-3D3935;
			}
		}

		&__footer {
			border-top: 1px solid #e8e7e5;
		}
		&__header-cell {
			position: relative;
			align-items: center;
		}
		&__header-cell-content {
			width: max-content;
		}
		&__header-left {
			.scl-pricing-table__header-cell {
				align-items: start;
			}
		}
		&__header-right {
			.scl-pricing-table__header-cell {
				align-items: flex-end;
			}
		}
		.v-datatable thead th {
			.v-icon {
				top: 0;
				left: 0;
				height: 10px;
			}
			&.desc {
				.v-icon {
					top: 0;
				}
			}
		}
		&__toggles {
			display: flex;
			justify-content: flex-end;
			> .v-input {
				flex-grow: 0;
			}
		}
		&__checkbox {
			.v-input__slot {
				margin-right: 0;
				transform: scale(0.8);
			}
			&.v-input--is-disabled {
				.v-input--selection-controls__input {
					width: 22px;
					height: 23px;
					background-color: $color-CCCBC9;
					border-radius: 3px;
				}
				.v-icon {
					display: none;
				}
			}
			&--indeterminate {
				.v-icon {
					color: $color-orange;
				}
			}
		}
		&__zindex {
			z-index: 1 !important;
		}
		&__selection-cta {
			display: flex;
			flex-direction: row;
			align-items: center;
			.v-btn {
				height: 12px;
				color: $color-orange;
				text-decoration: underline;
				&:before {
					display: none;
				}
			}
		}
		&__selection-cta-divider {
			width: 2px;
			height: 17px;
			margin-right: 8px;
			background-color: $color-CCCBC9;
		}
	}
}
