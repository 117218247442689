$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";
#app {
	.scl-preferences-list {
		.container.fluid {
			max-width: 100% !important;
		}
		&__toggles {
			display: flex;
			justify-content: flex-end;
			> .v-input {
				flex-grow: 0;
			}
		}
	}
}
