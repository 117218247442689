$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";
#app {
	.scl-role-access-modal {
		&__container {
			height: auto;
			font-size: 16px;
			@media (min-width: $md) {
				height: auto;
			}
		}
		&__actions {
			position: absolute;
			bottom: 0;
			display: flex;
			justify-content: flex-end;
			width: 100%;
			@media (max-width: $md) {
				justify-content: center;
			}
		}
		&__component {
			.underline {
				border-bottom: 1px solid #cccbc9;
			}
			.v-divider {
				min-width: 100%;
			}
		}
		&__list {
			list-style: none;
		}
	}
}
