$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";
#app {
	.scl-invoice-amount {
		&__container {
			background-color: $color-white;
			.v-datatable {
				tr {
					cursor: pointer;
				}
				td {
					font-size: 17px;
				}
			}
			.caption {
				color: $color-grey2;
			}
		}
		&__search {
			.v-btn {
				height: 54px;
			}
		}
		&__select {
			.v-label {
				text-transform: capitalize;
			}
		}
		&__action {
			align-items: center;
			display: flex;
			justify-content: flex-end;
		}
	}
	.scl-invoice-amount-modal__component {
		.scl-admin-table__checkbox {
			justify-content: center;
		}
	}
}
