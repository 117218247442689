$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";
#app {
	.scl-route-button {
		align-items: center;
		border-bottom: 1px solid;
		display: inline-flex;
		margin-bottom: 20px;
		text-decoration: none;
		&__label {
			font-size: 16px;
		}

		&--orange {
			.scl-route-button {
				border-bottom-color: $color-orange !important;

				.v-icon,
				&__label {
					font-weight: 300 !important;
					color: $color-orange !important;
				}
			}
		}
	}
}
