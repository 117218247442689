$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";
#app {
	.scl-manage-user-side-nav {
		width: 264px;
		.container.fluid {
			max-width: 100% !important;
		}
		&__profile-card {
			background-color: $color-CCCBC9;
			border-radius: 4px;
		}
		&__links {
			display: flex;
			flex-direction: column;
			&--active {
				font-weight: 700;
			}
		}
		&__status {
			margin-right: 4px;
			position: relative;
			top: -1px;
		}
	}
}
