$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";
#app {
	.scl-expandable-table {
		.container.fluid {
			max-width: 100% !important;
		}
		td {
			font-size: 14px;
		}
		.v-datatable {
			&__expand-row {
				background-color: #e8e7e5;
				.v-datatable__expand-col {
					z-index: 1 !important;
				}
			}
			thead {
				th {
					color: $color-3D3935;
					text-align: center !important;
					.v-icon {
						float: left;
						left: 0;
						position: relative;
						top: 4px;
						width: 100%;
					}
					&:first-child {
						background-color: $color-white;
					}
				}
			}
		}
		.underline {
			border-bottom: 1px solid #cccbc9;
		}
		ul {
			list-style: none;
		}

		table > tbody > tr > td:nth-child(1) {
			background: white;
			border-right: 2px solid $color-CCCBC9;
			left: -1px;
			margin-right: 8px;
			padding-right: 48px;
			position: sticky !important;
			position: -webkit-sticky !important;

			@media (min-width: $lg) {
				background: none;
				border: none;
				margin-right: 0;
				padding-right: 0;
			}
		}
		table > thead > tr > th:nth-child(1) {
			left: -1px;
			margin-right: 8px;
			// background: white;
			padding-right: 48px;
			position: sticky !important;
			position: -webkit-sticky !important;
			// z-index: 9998;

			@media (min-width: $lg) {
				background: none;
				margin-right: 0;
				padding-right: 0;
			}
		}

		a {
			&.scl-expandable-table__modal-link {
				color: $color-3D3935;
			}
			&.disabled {
				color: $color-CCCBC9;
				pointer-events: none;
			}
		}
		&__selection-cta {
			align-items: center;
			display: flex;
			flex-direction: row;
		}
		&__selection-cta-divider {
			background-color: $color-CCCBC9;
			height: 17px;
			margin-right: 8px;
			width: 2px;
		}
		&__footer {
			border-top: 1px solid #e8e7e5;
		}
		&__toggles {
			display: flex;
			justify-content: flex-end;
			> .v-input {
				flex-grow: 0;
			}
		}
		&__checkbox {
			.v-input__slot {
				margin-right: 0;
				transform: scale(0.8);
			}
			&--indeterminate {
				.v-icon {
					color: $color-orange;
				}
			}
		}
		&__expand-btn {
			cursor: pointer;
		}
		&__expanded-item {
			margin-right: 10px;
			max-width: 300px;
		}
		&__table-row--searchmatch {
			color: $color-orange;
		}
		&__zindex {
			z-index: 1 !important;
		}
	}
}
