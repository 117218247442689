#app {
	.scl-new-header {
		font-family: $font-name-klavika-medium;
		background-color: $color-grey8;

		.container.fluid {
			max-width: 100% !important;
		}
		.link-divider {
			border-right: 1px solid $color-9B9A99;
			margin-right: 22px;
			padding-right: 22px;
			height: 25px;
			display: none;
			@media (min-width: $md) {
				display: block;
			}
		}
		.divider-vertical {
			width: 1px;
			height: 45px;
			background-color: $color-CCCBC9;
			margin: 0 gutter-size(2);
			@media (max-width: $sm) {
				margin: gutter-size(2);
			}
		}
		&__title {
			font-size: 36px;
			font-weight: 300;
			color: $color-black;

			&--filter-label {
				color: $color-9B9A99;
			}
		}
		&__subtitle {
			display: flex;
			font-size: 16px;
			flex-direction: column;
			color: $color-black;

			@media (min-width: $md) {
				flex-direction: row;
			}
		}
		&__icon-group {
			display: flex;
			flex-direction: row;
			justify-content: flex-end;

			@media (max-width: $sm) {
				justify-content: flex-start;
			}

			.v-btn {
				width: 48px;
				height: 48px;
				margin: 0 gutter-size(1);

				@media (max-width: $sm) {
					margin: gutter-size(2);

					&:first-child {
						margin-left: 0;
					}
				}

				&.border {
					border: 2px solid $color-orange;
					border-radius: 0;
				}
				&.fit-content {
					width: fit-content;
				}
			}
		}
		&__icon {
			padding: 10px 0;
			font-size: 48px;
			font-weight: 100;
			&--help {
				height: 48px;
				font-size: 24px;
				border: 2px solid $color-orange;
				border-radius: 50%;
				padding: 10px 13px;
			}
			&--contact {
				border: 2px solid $color-orange;
				border-radius: 0;
			}
		}
		&__title-filter {
			display: flex;
			align-items: center;
		}
		&__title-select {
			.v-input__slot {
				background: white !important;
				border-radius: 0;
				border: 1px solid $colour-border;
				font-style: normal;
				font-weight: 500;
				font-size: 16px;
				color: $accent;
			}
			&--wide {
				.v-input__slot {
					width: 100%;
					min-width: 288px;

					@media (min-width: $sm) {
						width: 430px;
					}
				}
			}
			&.v-input--is-disabled input {
				max-height: 16px;
				color: $accent;
			}
		}
		&__title-input {
			background: white !important;
			border-radius: 0;
			border: 1px solid $colour-border;
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			align-items: center;
			padding: gutter-size(3.75) gutter-size(3);
			display: flex;
			align-items: center;
			justify-content: space-between;
			cursor: pointer;
			min-width: 288px;

			@media (min-width: $sm) {
				width: 430px;
			}
			&--label {
				color: rgba(0, 0, 0, 0.87);
				cursor: pointer;
			}
			&--active {
				border-color: $color-orange;
			}
		}
		.v-input .v-input__icon--append .v-icon {
			color: $color-orange;
		}
		.divider {
			width: 1px;
			max-height: 48px;
			margin: 0 gutter-size(2);
			background-color: $colour-border;
		}
		&__no-site-available {
			max-height: 400px;
			position: relative;
			overflow: hidden;
			&__item {
				position: sticky;
				bottom: 0;
				display: flex;
				padding: gutter-size(3);
				background: $colour-input;
				cursor: pointer;
				span {
					font-weight: 500;
				}
			}
			&__search {
				padding: gutter-size(4);
				background-color: $colour-input;				
				.v-input__slot {
					background: $color-white !important;
				}
				.v-icon{
					color : $color-orange;
				}
				::-webkit-input-placeholder {
					color: $accent;
				}
			}
			&--show-search {
				overflow: visible;
				.scl-new-header__no-site-available__search {
					display: block;
				}
			}
			.v-list {
				padding: 0;
			}
			.v-list__tile__title {
				font-weight: 500;
			}
		}
	}
}
