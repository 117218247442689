$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";
#app {
	.scl-simple-list {
		.container.fluid {
			max-width: 100% !important;
		}
		&__data-table {
			overflow-y: auto;
		}
	}
}
