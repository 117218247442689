$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";
// grid list

#app {
	.scl-2-col {
		&.container.fluid {
			max-width: 100% !important;
		}
	}
}
