#app {
	.v-list__tile{
		&:hover{
			color: $color-orange;
		}
		&__title {
			font-weight: 700;
		}
	}
	.scl-form {
		a {
			color: $color-orange;
			#{$interact} {
				color: $color-orange-hover-light;
			}
		}
		.underline {
			border-bottom: 1px solid $color-CCCBC9;
		}
		.caption {
			text-transform: uppercase;
		}
		&__title {
			margin-bottom: 26px;
			font-size: 36px;
			font-family: $font-name-klavika-bold;
			line-height: 1.4;
			letter-spacing: 1px;
			text-transform: uppercase;
		}
		&__input {
			.v-input__slot {
				background: $color-E5E5E5 !important;
				border: 1px solid #cbcbce;
				border-radius: 0;
				box-shadow: inset 0 1px 2px hsla(0, 0%, 4%, 0.1);
			}
			input {
				color: #0a0a0a;
				font-weight: 700;
			}
			.v-select__selection {
				font-weight: 700;
			}
			
			&.v-input--is-readonly:not(.v-input--is-disabled)  .v-input__slot {
				background: transparent !important;
				input {
				color: $color-grey1 !important
				}
			}
			&.v-input--is-disabled .v-input__slot {
				border: 0;
				box-shadow: none;
			}

			&.v-textarea textarea {
				color: $accent;
				padding: 16px 16px 16px 4px;
			}
		}
		&__checkbox {
			.v-input__slot {
				background: none !important;
				border: 0;
				box-shadow: none;
			}
			.v-icon {
				color: #cbcbce;
			}
		}
		&__label,
		label {
			color: $primary-color;
			font-weight: 700;
			font-size: 14px;
			font-family: $font-name-klavika-bold;
			line-height: 1.3;
			text-transform: uppercase;
			&.v-label--active {
				color: $color-orange;
			}
		}
		&__btn {
			height: 56px;
			margin: 0 auto 20px;
			font-weight: 700;
			font-size: 17px;
			font-family: $font-name-klavika-medium;
			line-height: 1.1;
			letter-spacing: 1.5px;
			text-transform: uppercase;
		}
		&__terms {
			padding: 0 20px;
			color: $primary-color;
			font-weight: 400;
			font-size: 11px;
			line-height: 20px;
			letter-spacing: 0.5px;
			text-align: center;
			a {
				color: $color-orange;
				text-decoration: none;
				#{$interact} {
					color: $color-orange-hover-light;
				}
			}
		}
		&__step-number {
			display: flex;
			flex-grow: initial;
			width: 145px;
			border: 2px solid $color-orange;

			input[type="number"]::-webkit-inner-spin-button,
			input[type="number"]::-webkit-outer-spin-button {
				margin: 0;
				-webkit-appearance: none;
			}
			input[type="number"] {
				-moz-appearance: textfield;
			}
			input {
				font-size: 24px;
			}
			.v-icon {
				color: $color-orange;
				font-size: 18px;
			}
			.v-input__control {
				min-height: 49px;
			}
			.v-input__prepend-outer {
				margin-left: 9px;
			}
			.v-input__append-outer {
				margin-right: 9px;
			}
			.v-input__slot {
				padding: 0;
				background-color: rgba(0, 0, 0, 0);
			}
			input {
				text-align: center;
			}
		}
	}
}
