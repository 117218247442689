$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";

















































































































































































































.scl-session-timeout-modal {
	::v-deep &__component {
		.v-card__title {
			align-items: baseline;
		}
		.scl-dialog__toolbar {
			background-color: $color-orange !important;
		}
		.v-toolbar__content {
			max-width: 100% !important;
		}
		.scl-dialog__toolbar-title {
			padding-left: 1rem;
			color: #fff !important;
		}
	}
}
