// settings/variables

// breakpoints
$xs: 599px;
$sm: 600px;
$md: 960px;
$lg: (1280px + 16px);
$xl: (1920px + 16px);

$spacer: 4px;
@function gutter-size($gutter-multiplier) {
	@return $spacer * $gutter-multiplier;
}
// colours
$primary-color: #333;
$color-white: #fff;
$color-black: #000;
$color-orange: #ff6105;
$color-orange-hover-light: #ffa069;
$color-red: #e80000;
$color-green: #63bf00;
$color-E5E5E5: #e5e5e5;
$color-CCCBC9: #cccbc9;
$color-3D3935: #3d3935;
$color-151011: #151011;
$color-9B9A99: #9b9a99;
$color-F3F3F3: #f3f3f3;
$color-4E4A46: #4e4a46;

$color-grey1: #999;
$color-grey2: #817e79;
$color-grey3: #9b9a99;
$color-grey4: #b6b5b3;
$color-grey5: #d9d8d6;
$color-grey7: #e8e7e5;
$color-grey8: #F8F8F8;

$color-due-past-30: #ff6105;
$color-due-within-30: #63bf00;
$color-due-within-5: #ecaf1e;
$color-due-current: #060;
$color-due-nodata: #b6b5b3;

// elements
$accent: #54565a;
$colour-border: #c8c8c8;
$colour-input: $color-grey8;

// typography
$font-name-klavika-bold: 'Arial Black', 'Helvetica Neue', 'Helvetica', sans-serif;
$font-name-klavika-medium: 'Arial', 'Helvetica Neue', 'Helvetica', sans-serif;
$font-name-klavika-light: 'Arial', 'Helvetica Neue', 'Helvetica', sans-serif;
$font-name-coates-klavika-bold: 'Arial Black', 'Helvetica Neue', 'Helvetica', sans-serif;
$font-name-coates-klavika-regular: 'Arial', 'Helvetica Neue', 'Helvetica', sans-serif;
$font-name-pt-sans: 'Arial', 'Helvetica Neue', 'Helvetica', sans-serif;
$font-name-pt-sans-bold: 'Arial Black', 'Helvetica Neue', 'Helvetica', sans-serif;
$font-name-pt-sans-italic: 'Arial', 'Helvetica Neue', 'Helvetica', sans-serif;
$font-name-neue-hass-display-pro: 'Neue Hass Grotesk Display Pro', 'Arial', 'Helvetica Neue', 'Helvetica', sans-serif;

// font sizes
$font-size-base-px: 16px;
$font-size-sm: $font-size-base-px * 0.875;
$font-size-base: $font-size-base-px * 1;
$font-size-lg: $font-size-base-px * 1.5;
$font-size-xl: $font-size-base-px * 2;

// font sizes: headings
// h6
$h6-font-size: $font-size-base-px * 1;
$h6-font-size-min: $font-size-base-px * 1;
// h5
$h5-font-size: $font-size-base-px * 1.5;
$h5-font-size-min: $font-size-base-px * 1.25;
// h4
$h4-font-size: $font-size-base-px * 2.5;
$h4-font-size-min: $font-size-base-px * 2;
// h3
$h3-font-size: $font-size-base-px * 3;
$h3-font-size-min: $font-size-base-px * 2.25;
// h2
$h2-font-size: $font-size-base-px * 4;
$h2-font-size-min: $font-size-base-px * 3.5;
// h1
$h1-font-size: $font-size-base-px * 5;
$h1-font-size-min: $font-size-base-px * 4.5;

// line height
$line-height-base: 1.25;
$line-height-lg: 1.5;

$font-weight-normal: 400;
$font-weight-semi-bold: 500;
$font-weight-bold: 600;

// paths
$font-path: '/assets/fonts/neue-haas-display-pro'; // '#{$font-path}/NeueHaasDisplayLight.woff'
// z-index
$zindex: (
	penthouse: 111111,
	level5: 11111,
	level4: 1111,
	level3: 111,
	level2: 11,
	level1: 1,
	ground: 0,
	basement: -1,
	hell: -111111
);
@function zindex($key) {
	@return map-get($zindex, $key);
	@if not map-has-key($zindex, $key) {
		@warn "zindex '#{$key}' not found.";
	}
} // zindex(level1);

// interactivity
$interact: '&:focus, &:hover, &:active';
$easing: ease-in-out;
$timing: (
	fastest: 0.1s,
	faster: 0.2s,
	fast: 0.3s,
	default: 0.5s,
	slow: 0.7s,
	slower: 0.8s,
	slowest: 0.9s
);
@function timing($key) {
	@return map-get($timing, $key);
	@if not map-has-key($timing, $key) {
		@warn "timing '#{$key}' not found.";
	}
} // timing(fast);
