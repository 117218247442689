$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";
#app {
	.scl-button {
		background-color: $color-orange;
		border: 2px solid $color-orange;
		border-radius: 0;
		color: $color-white;
		cursor: pointer;
		font-family: $font-name-klavika-medium;
		font-size: 17px;
		font-weight: 700;
		height: auto;
		// line-height: 48px;
		margin: 0;
		padding: 0 16px;
		position: relative;
		&--outline {
			color: $color-orange;
		}
		&--small {
			font-size: 15px;
			line-height: 36px;
			padding: 0 16px;
		}
		&__icon {
			font-size: 17px !important;
			margin-right: 8px;
			
			&.fa-file-csv {
				margin-right: 0;
				font-size: 30px !important;
				font-weight: 100;
			}
		}
		&.v-btn--disabled {
			border: none;
		}
		&.v-btn__content {
			line-height: 100%;
		}
		&__tally {
			color: #fff;
			position: absolute;
			top: -12px;
			right: -15px;
			font-size: 10px;
			z-index: 1;
			display: flex;
			justify-content: center;
			align-items: center;
			p {
				position: absolute;
			}
			&:after {
				content: "";
				padding: 5px;
				border-radius: 50%;
				width: 17px;
				height: 17px;
				background-color: $color-orange;
				position: absolute;
				z-index: -1;
			}
		}
	}
}
