$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";
#app {
	.scl-user-access {
		.container.fluid {
			max-width: 100% !important;
		}
		.v-label {
			font-weight: normal;
			text-transform: capitalize;
			font-family: $font-name-klavika-medium;
		}
		.v-input--radio-group__input {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			max-width: 50%;
		}
		.v-radio {
			flex-grow: 1;
			margin-bottom: 8px;
		}
		&__title {
			font-size: 24px;
		}

		.scl-site-access-accordion {
			max-width: 50%;
		}
	}
}
