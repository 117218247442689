// components
div#app {
	.scl-form {
		label {
			font-family: $font-name-klavika-medium;
			text-transform: capitalize;
		}
		&__switch {
			&.v-input--is-dirty {
				.v-input--switch__thumb {
					color: $color-orange;
				}
				.v-input--switch__track {
					background-color: $color-grey4;
				}
			}
			.v-input--selection-controls__input {
				width: 50px;
				height: 35px;
				margin-right: 0;
				input {
					max-height: none;
				}
			}
			.v-input--selection-controls__ripple {
				top: -5px;
				left: -8px;
				display: none;
				width: 40px;
				height: 40px;
				&.accent--text {
					transform: translate(19px, 0);
				}
			}
			.v-input--switch__track {
				top: 0px;
				left: -2px;
				width: 50px;
				height: 31px;
				background: $color-E5E5E5;
				border-radius: 23px;
			}
			.v-input--switch__thumb {
				position: relative;
				top: 3px;
				width: 25px;
				height: 25px;
				color: $color-white;
				&.accent--text {
					transform: translate(19px, 0);
				}
			}
		}
		&__input {
			.v-input__slot {
				height: 48px;
				min-height: 48px;
			}
			.v-input__prepend-inner,
			.v-input__append-inner {
				margin-top: 11px;
			}
			.v-select__selections {
				padding-top: 0;
			}
			.v-text-field__slot {
				display: flex;
				align-items: center;
			}
			.v-label {
				top: 15px;
				font-weight: 500;
				font-family: $font-name-klavika-medium;
				text-transform: capitalize;
			}
			.v-label--active {
				top: 9px;
				color: $color-4E4A46;
			}
			input {
				margin-top: 13px;
				font-weight: 500;
			}
			&.v-textarea {
				.v-input__slot {
					height: auto;
				}
				textarea {
					height: 100%;
					margin-top: 0;
					resize: none;
				}
			}
			&--optional {
				&:before {
					position: absolute;
					top: -20px;
					right: 0;
					font-size: 10px;
					content: "(Optional)";
				}
			}
		}
		&__select {
			.v-select__selection {
				margin-top: 22px;
				font-weight: 500;
			}
			.v-icon {
				font-size: 18px;
			}
			&.v-text-field--solo {
				.v-select__selection {
					margin-top: 10px;
					font-weight: 500;
				}
				.v-input__append-inner {
					margin-top: 0;
				}
			}
			&--checkbox {
				.fa-check-square {
					color: $color-orange !important;
				}
			}
		}
		&__date-select {
			background-color: $color-E5E5E5;
			border: 1px solid $color-CCCBC9;
			&.v-input {
				margin-top: 0;
				padding: 13px 9px 0px;
			}
			.v-input__slot {
				min-height: 33px;
				&:before {
					display: none;
				}
			}
			&.v-text-field {
				.v-input__append-inner {
					position: absolute;
					right: 0;
					margin-top: -2px;
					pointer-events: none;
				}
			}
		}
	}
	.scl-dialog {
		&__toolbar {
			border-bottom: 1px solid $color-grey7;
			.v-toolbar__content {
				max-width: 90%;
			}
			.v-btn {
				top: 10px;
				right: 10px;
				.v-icon {
					color: $color-4E4A46;
					font-size: 24px;
				}
			}
		}
		&__toolbar-title {
			color: $color-4E4A46;
			font-weight: bold;
			font-size: 24px;
			font-family: $font-name-klavika-medium;
			text-transform: capitalize;
		}
		&__alert {
			color: red;
		}
	}
	.scl-button {
		height: 48px;
		text-transform: capitalize;
	}
	.container {
		&.fluid {
			max-width: 100%;
		}
	}
	.query {
		&--searchmatch {
			color: $color-orange;
		}
	}
	.v-datatable {
		thead {
			th {
				.v-icon {
					position: relative;
					top: 4px;
					left: 10px;
					color: $color-3D3935;
					opacity: 1;
				}
				&.desc {
					.v-icon {
						top: -2px;
					}
				}
			}
		}

		td {
			font-size: 16px;
		}

		.v-datatable__actions {
			position: relative;
			.scl-datatable__action-date {
				left: 0;
				position: absolute;
				top: 50%;
				transform: translateY(-50);
			}
		}
	}
	.layout-border {
		&__bottom-grey {
			border-bottom: 4px solid $color-E5E5E5;
		}
		&__right-grey {
			border-right: 4px solid $color-E5E5E5;
		}
		&__left-grey {
			border-left: 4px solid $color-E5E5E5;
		}
		&__top-grey {
			border-top: 4px solid $color-E5E5E5;
		}
	}
	.featured {
		color: $color-orange;
		font-weight: bold;
	}
	.lg5-custom {
		@media (min-width: $md) {
			flex-basis: 20%;
			width: 20%;
			max-width: 20%;
		}
	}
	.format-break-spaces {
		white-space: break-spaces;
	}
	.icon-link {
		padding-bottom: 2px;
		border-bottom: 1px solid $color-orange;
	}
}
