.scl-alert-modal {
	&__checkboxes,
	&__radio-buttons {
		.v-input__control {
			.v-input__slot {
				margin: 0 !important;
			}
		}
	}

	&__checkboxes {
		border: 0;

		.v-input:not(:first-child),
		.v-input--selection-controls {
			margin: 0;
		}

		.v-input--checkbox {
			display: inline-flex;
			margin-right: 1rem;
		}
	}

	&__radio-buttons {
		.v-input--radio-group__input {
			flex-direction: row !important;
			align-items: flex-start;
		}
	}

	&__section {
		&--overline {
			border-top: 1px solid $color-grey7;
		}
	}
}
