$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";
#app {
	.scl-user-details-form {
		.error--text {
			.v-input__slot {
				border: 1px solid $color-orange;
			}
		}
		.container.fluid {
			max-width: 100% !important;
		}
		&__title {
			font-size: 24px;
		}
		&__actions {
			flex-direction: column-reverse;
			@media (min-width: $md) {
				flex-direction: row;
			}
			.v-btn {
				@media (max-width: $sm) {
					width: 100%;
				}
			}
		}
	}
}
