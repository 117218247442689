$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";
#app {
	.scl-login-message-modal {
		&__container {
			font-size: 16px;
			height: 80%;
		}
		&__container-layout {
			overflow-y: auto;
		}
		&__status {
			width: 90%;
		}
	}
	.scl-login-message-modal-component {
		.v-divider {
			min-width: 100%;
		}
		.scl-form__date-select {
			width: 50%;
		}
		.v-input--switch {
			.v-input__slot {
				margin-bottom: 0;
			}
		}
		input {
			font-weight: 700;
		}
		.v-card__actions {
			@media (max-width: $sm) {
				display: flex;
				flex-direction: column-reverse;
				.v-btn {
					width: 100%;
				}
			}
		}
		.v-textarea {
			textarea {
				height: 100%;
				margin-top: 0;
			}
		}
	}
}
