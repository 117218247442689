$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";
#app {
	.scl-contact-form {
		&__call {
			align-items: center;
			color: $primary-color;
			display: inline-flex;
			flex-direction: row;
			text-decoration: none;
			.v-icon {
				font-size: 17px;
				margin-right: 8px;
			}
			span {
				font-family: $font-name-klavika-bold;
				font-size: 28px;
				letter-spacing: 1px;
				line-height: 34px;
			}
		}
		&__list {
			background: none;
			margin-top: 16px;
			.v-list__tile {
				padding: 0;
			}
			.v-list__tile__action {
				min-width: 30px;
			}
			.v-icon {
				color: $color-orange;
				font-size: 12px;
			}
			.v-list__tile__title {
				font-size: 14px;
			}
		}
	}
}
