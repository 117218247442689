$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";
#app {
	.scl-footer {
		background-color: $color-orange;
		min-height: 130px;
		@media (min-width: $md) {
			min-height: 60px;
		}
		&__list-container {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			> div {
				height: 16px;
				display: flex;
				align-items: center;
			}
			div:first-child {
				border-width: 0;
				margin-bottom: 20px;
			}
			@media (min-width: $md) {
				flex-direction: row;
				align-items: center;
				div:first-child {
					border-width: 1px;
					margin-bottom: 0;
				}
			}
		}
		&__call {
			color: $color-white;
			text-decoration: none;
			margin-left: 10px;
			margin-bottom: 10px;
			@media (min-width: $md) {
				margin-left: 0;
				margin-bottom: 0;
			}
			.v-icon {
				margin-right: gutter-size(1);
				color: $color-white;
				position: relative;
				top: -3px;
			}
			span {
				font-size: 20px;
			}
		}
		&__list-item {
			color: $color-white;
			font-family: $font-name-klavika-medium;
			font-size: 14px;
			height: 16px;
			line-height: 24px;
			padding: 0;
			display: flex;
			align-items: center;
		}
		.link-divider {
			border-right: 1px solid $color-white;
			margin-right: 22px;
			padding-right: 22px;
		}
	}
}
