body {
	.application {
		font-family: $font-name-klavika-medium;

		&.theme--light {
			color: $accent;
			th {
				color: rgba($color-black, 1);
			}
			.v-sheet {
				color: $color-4E4A46;
			}
		}
		.headline {
			font-family: $font-name-klavika-medium !important;
		}
	}
}
