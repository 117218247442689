$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";
#app {
	.scl-select {
		margin: 24px auto;
		max-width: 768px;
		.v-text-field__details {
			display: none;
		}
	}
}
