#app {
	.wrapper-content {
		display: flex;
		&__container {
			background-color: $color-white;
			height: 100%;
			padding-left: 80px;
			position: absolute;
			right: 0;
			width: 100%;
			@media (max-width: $md) {
				padding-left: 0;
				top: 60px;
				height: calc(100% - 60px);
			}
			&--grey {
				background-color: $color-grey8;
			}
		}
		.overflow-y-scroll {
			overflow-y: auto;
		}
	}
}
