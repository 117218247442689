$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";
#app {
	.scl-card-cta-enhanced {	
		&__card {
			&--light {
				.scl-card-cta-enhanced__title,
				.scl-card-cta-enhanced__subtitle,
				.scl-card-cta-enhanced__text {
					color: $color-black;
				}
	
				.scl-card-cta-enhanced__cta.scl-button {
					background-color: $color-orange;	
					color: $color-white;
					
					&:hover {
						background-color: $color-white;
						color: $color-orange;	
					}

					&--no-fill {
						background-color: $color-white;
						border: none;
						color: $color-orange;	
			
						&:hover {
							color: $color-white;
							border: none;
							background-color: $color-orange;	
						}
					}
				}
				
			}

			&--orange {
				background-color: #FF6105;
			}
			&--gold {
				background-color: #91804A;
			}
			&--blue {
				background-color: #273769;
			}
			&--ochre {
				background-color: #BF4A29;
			}
			&--green {
				background-color: #587735;
			}			
		}

		&__title,
		&__subtitle,
		&__text {
			color: $color-white;
			letter-spacing: .025em;
			font-weight: 400;
		}

		&__title {
			line-height: 125%;
			font-size: 64px;
		}

		&__subtitle {
			line-height: 150%;
			font-size: 24px;
		}

		&__text {
			line-height: 125%;
			font-size: 48px;
		}

		&__cta.scl-button {
			background-color: $color-white;
			border: none;
			color: $color-orange;	

			&:hover {
				color: $color-white;
				border: none;
				background-color: $color-orange;	
			}

			&--filled {
				background-color: $color-orange;	
				color: $color-white;
				
				&:hover {
					background-color: $color-white;
					color: $color-orange;	
				}	
			}
		}
		
		.container {
			height: 100%;
			position: relative;
			z-index: 1;
			padding-top: 80px;
			padding-bottom: 80px;
		}

		&__image {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;

			&--desktop {
				display: none;
			}

			@media (min-width: $sm) {
				display: none;
	
				&--desktop {
						display: block;
				}
			}
		}

		&__content {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: space-between;
			height: 100%;
			text-align: left;
			gap: 40px;

			&--left-align {
				text-align: left;
				align-items: flex-start;
			}
			&--right-align {
				text-align: right;
				align-items: flex-end;
			}
			&--center-align {
				text-align: center;
				align-items: center;
			}		

			> button {
				width: fit-content;
				padding: 25px 40px;
			}

			@media (min-width: $md) {
				flex-direction: row;
				gap: 64px;
				align-items: center;
	
				&--left-align {
					text-align: left;					
				}
				&--right-align {
					text-align: right;
					flex-direction: row-reverse;
				}
				&--center-align {
					flex-direction: column;
					text-align: center;
					gap: 24px;
				}		
			}
		}

		&__text-content {
			display: flex;
			flex-direction: column;
			gap: 8px;
		}		
	}
}
