$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";
#app {
	.scl-card-contact-simple {
		background: none;
		display: flex;
		flex-wrap: wrap;
		&__name {
			align-items: center;
			display: flex;
			font-size: 16px;
			position: relative;
			top: -3px;
		}
	}
}
