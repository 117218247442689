$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";
#app {
	.scl-bottom-sheet-featured {
		&__card {
			min-height: 415px;
			background-color: rgba($color-3D3935, 0.9);
			display: flex;
			@media (min-width: $md) {
				min-height: 140px;
			}
			.scl-button {
				height: 45px;
			}
			#featuredVideo {
				width: 100%;
				@media (min-width: $md) {
					width: 200px;
				}
			}
		}
		&__container {
			margin-top: 60px;
			@media (min-width: $md) {
				margin-top: 18px;
			}
		}
		&__close-button {
			position: absolute;
			right: 0;
			top: 10px;
			font-size: 14px;
			@media (min-width: $md) {
				top: auto;
			}
		}
		&__title {
			color: $color-orange;
			font-size: 18px;
			letter-spacing: 0.5px;
		}
		&__subtitle {
			color: $color-white;
		}
		&__input-cb {
			@media (min-width: $md) {
				margin-left: 30px;
			}
			.v-input__slot {
				margin-bottom: 0;
			}
		}
		&__sheet-controls {
			flex-direction: column;
			align-items: center;
			@media (min-width: $md) {
				flex-direction: row;
				align-items: left;
			}
		}
	}
}
