$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";
#app {
	.scl-access-request-table {
		&__empty-row {
			height: 200px;
			vertical-align: text-top;
		}
		.container.fluid {
			max-width: 100% !important;
		}
		thead {
			th {
				color: $color-3D3935;
				.v-icon {
					color: $color-3D3935;
					left: 10px;
					opacity: 1;
					position: relative;
					top: 4px;
				}
				&.desc {
					.v-icon {
						top: -2px;
					}
				}
				&:first-child {
					background-color: $color-white;
				}
			}
		}

		a {
			&.grey-link {
				color: $color-3D3935;
			}
		}
		&__list {
			list-style: none;
		}
		&__footer {
			border-top: 1px solid #e8e7e5;
		}
		&__zindex {
			z-index: 1 !important;
		}
	}
}
