#app {
	.scl-dialog {
		height: 0;
		width: 0;
		&__toolbar {
			background: none;
			border-top-left-radius: 0 !important;
			border-top-right-radius: 0 !important;
			.v-btn {
				border: 2px solid #333;
				border-radius: 0;
				height: 39px;
				margin: 0;
				position: absolute;
				right: 3px;
				top: 3px;
				width: 39px;
				.v-icon {
					color: #333;
					font-size: 28px;
					left: 1px;
					position: relative;
					top: 1px;
				}
			}
			.v-toolbar__content {
				padding-left: 16px;
			}
		}
		&__toolbar-title {
			font-family: $font-name-klavika-bold;
			font-size: 23px;
			letter-spacing: 1px;
			line-height: 1.4;
			margin-bottom: 0;
			margin-top: -6px;
			text-transform: uppercase;
			@media (max-width: $md) {
				font-size: 18px;
				letter-spacing: 0px;
			}
		}
	}
}
