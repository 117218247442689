$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";
#app {
	.scl-add-customer-modal {
		&__container {
			background-color: $color-white;
			.v-datatable {
				tr {
					cursor: pointer;
				}
				td {
					font-size: 17px;
				}
			}
		}
		&__search {
			.v-btn {
				height: 54px;
			}
		}
	}
	.scl-add-customer-modal__component {
		height: 765px;
		.scl-admin-table__checkbox {
			justify-content: center;
		}
	}
}
