$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";
#app {
	.scl-change-account-modal {
		&__container {
			.v-datatable {
				tr {
					cursor: pointer;
				}
				td {
					font-size: 17px;
				}
			}
		}
	}
	.scl-change-account-modal-component {
		height: 765px;
	}
}
