#app {
	.scl-account-access-modal-component {
		height: 100%;
		min-height: 80%;
		overflow: hidden;
		.v-form {
			position: absolute;
			top: 20%;
			width: 100%;
			height: 80%;
			overflow-y: scroll;
			@media (min-width: $md) {
				top: 10%;
				height: 90%;
			}
		}
		.topline {
			border-top: 1px solid #ccc;
		}
	}
}
