$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";
#app {
	.scl-pie-chart-panel {
		&__legend {
			> div {
				align-items: center;
				display: flex;
			}
		}
		&__no-data {
			align-items: center;
			display: flex;
			flex-direction: column;
			justify-content: center;
			min-height: 200px;
		}
	}
}
