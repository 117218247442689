$env: production;@import "@/_scl-portals/assets/css/settings/_variables.scss";@import "@/_scl-portals/assets/css/tools/_mixins.scss";
#app {
	.scl-expandable-invoice-table {
		&__disputed-invoice {
			color: $color-orange;
		}
		.container.fluid {
			max-width: 100% !important;
		}
		.v-datatable {
			&__expand-row {
				background-color: #e8e7e5;
				.v-datatable__expand-col {
					z-index: 1 !important;
				}
			}
			&__actions {
				position: relative;
			}
		}

		.underline {
			border-bottom: 1px solid #cccbc9;
		}
		ul {
			list-style: none;
		}
		.v-datatable thead {
			th {
				color: $color-3D3935;
				text-align: center !important;
				.v-icon {
					color: $color-3D3935;
					float: left;
					left: 0;
					opacity: 1;
					position: relative;
					top: 4px;
					width: 100%;
				}
				&.desc {
					.v-icon {
						top: -2px;
					}
				}
				&:first-child {
					background-color: $color-white;
				}
			}
		}
		table > tbody > tr > td:nth-child(1) {
			background: white;
			border-right: 2px solid $color-CCCBC9;
			left: -1px;
			margin-right: 8px;
			padding-right: 48px;
			position: sticky !important;
			position: -webkit-sticky !important;

			@media (min-width: $lg) {
				background: none;
				border: none;
				padding-right: 0;
			}
		}
		table > thead > tr > th:nth-child(1) {
			left: -1px;
			position: sticky !important;
			position: -webkit-sticky !important;
		}

		a {
			&.scl-expandable-table__modal-link {
				color: $color-3D3935;
			}
		}

		&__footer {
			border-top: 1px solid #e8e7e5;
		}
		&__toggles {
			display: flex;
			justify-content: flex-end;
			> .v-input {
				flex-grow: 0;
			}
		}
		&__checkbox {
			.v-input__slot {
				margin-right: 0;
				transform: scale(0.8);
			}
			&--indeterminate {
				.v-icon {
					color: $color-orange;
				}
			}
		}
		&__expand-btn {
			cursor: pointer;
		}
		&__expanded-item {
			max-width: 200px;
			&--empty {
				color: $color-grey3;
			}
		}
		&__table-row--searchmatch {
			color: $color-orange;
		}
		&__zindex {
			z-index: 1 !important;
		}
	}
}
